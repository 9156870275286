import {
  Box,
  Button,
  Container,
  Flex,
  Image,
  Switch,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import info from "innovate/assets/images/info.svg";
import vectorback from "innovate/assets/images/vectorback.svg";
import Header from "innovate/components/common/Header";
import { Option } from "innovate/components/Inputs/MultiSelectInput";
import SelectInput from "innovate/components/Inputs/SelectInput";
import ExploreCountry from "innovate/pages/InnoExplore/ExloperCountry";
import {
  updatedExploreAbs,
  updatedExplorePerWithPercentage,
} from "innovate/utils/Constants";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const GlobalView: React.FC = () => {
  const zone = ["ALL", "SAF", "MAZ", "SAZ", "NAZ", "APAC", "AFR"];
  const market = ["South Africa", "Colombia"];
  const years = ["2024", "2023"];
  const aggregates = ["Volume", "Net Revenue"];
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [innoData, setInnoData] = useState(updatedExplorePerWithPercentage);
  const [innoDefaultData, setInnoDefaultData] = useState(
    updatedExplorePerWithPercentage
  );
  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setInnoData(updatedExploreAbs);
      setInnoDefaultData(updatedExploreAbs);
    } else {
      setInnoData(updatedExplorePerWithPercentage);
      setInnoDefaultData(updatedExplorePerWithPercentage);
    }
    setIsSwitchOn(event.target.checked);
  };

  const createData = <T extends string | number>(
    items: T[]
  ): { value: T; label: string }[] =>
    items.map((item: T) => ({
      value: item,
      label: item.toString(),
    }));
  const zoneData = createData(zone);
  const marketData = createData(market);
  // const yearData = createData(years);
  const aggregatedData = createData(aggregates);
  const [selectedZone, setSelectedZone] = useState<Option | null>(null);
  const [selectedMarket, setSelectedMarket] = useState<Option | null>(null);
  const [selectedYear, setSelectedYear] = useState<Option>([]);
  const [selectedRange, setSelectedRange] = useState<Option[]>([]);
  const [selectedAggregate, setSelectedAggregate] = useState<Option | null>(
    null
  );
  const [selected, setSelected] = useState<"Y" | "Q" | "M">("Y");

  const filterInnoData = (
    filterType: string,
    selectedOptions: Option | null
  ) => {
    if (!selectedOptions) return;
    if (selectedOptions.value === "ALL") {
      setInnoData(innoDefaultData);
      return;
    }

    const filteredData = innoDefaultData.filter((data) => {
      if (filterType === "zone") {
        return data.zone === selectedOptions.value;
      }
      // Add other filter conditions as needed
      return true;
    });

    setInnoData(filteredData);
  };

  const handleFilter = (filterType: string, selectedOptions: Option | null) => {
    if (filterType === "zone") {
      setSelectedZone(selectedOptions);
    }

    if (filterType === "market") {
      setSelectedMarket(selectedOptions);
    }
    if (filterType === "year" && selectedOptions) {
      setSelectedYear(selectedOptions);
    }
    if (filterType === "aggregate") {
      setSelectedAggregate(selectedOptions);
    }
    filterInnoData(filterType, selectedOptions);

    // Handle other filter types if needed
  };
  const [isLargerThan1250px] = useMediaQuery("(min-width: 1250px)");
  const customWidth = isLargerThan1250px ? "150px" : "130px"; // Adjust the widths as needed


  const getMonthNames = (): string[] => {
    const formatter = new Intl.DateTimeFormat("en-US", { month: "long" });
    return Array.from({ length: 12 }, (_, i) =>
      formatter.format(new Date(0, i))
    );
  };

  const generateMonths = (): { value: string; label: string }[] => {
    const months = getMonthNames();
    return years.flatMap((year) =>
      months.map((month, index) => ({
        value: `${year}-${String(index + 1).padStart(2, "0")}`, // Format: "2024-01"
        label: `${month} ${year}`, // Format: "January 2024"
      }))
    );
  };

  const generateQuarters = (): { value: string; label: string }[] => {
    const quarters = ["Q1", "Q2", "Q3", "Q4"];
    return years.flatMap((year) =>
      quarters.map((quarter, index) => ({
        value: `${year}-Q${index + 1}`, // Format: "2024-Q1"
        label: `${quarter} ${year}`, // Format: "Q1 2024"
      }))
    );
  };
  useEffect(() => {
    const yearData = createData(years);
    setSelectedRange(yearData); // Years as options
  }, []);

  const handleSelection = (type: "Y" | "Q" | "M") => {
    setSelected(type);
    if (type === "Y") {
      const yearData = createData(years);
      setSelectedRange(yearData); // Years as options
    } else if (type === "Q") {
      setSelectedRange(generateQuarters()); // Quarters as options
    } else if (type === "M") {
      setSelectedRange(generateMonths()); // Months as options
    }
  };

  const getCirclePosition = () => {
    switch (selected) {
      case "Y":
        return "3%"; // Align with "Y"
      case "Q":
        return "48%"; // Align with "Q"
      case "M":
        return "94%"; // Align with "M"
      default:
        return "48%";
    }
  };

  return (
    <>
      <Header />

      <Container mb={10} w="96%">
        <Flex mt={6}>
          <Link to="/inno-explore">
            <Text
              ml={2}
              //   variant="custom"
              fontWeight="bold"
              textDecorationStyle="solid"
              fontSize="14px"
            >
              &lt; &nbsp; Overview &nbsp;\
            </Text>
          </Link>
          <Flex>
            <Text
              ml={2}
              //   variant="custom"
              fontSize="14px"
              fontWeight={800}
              textDecoration="underline"
            >
              {" "}
              Global View
            </Text>
          </Flex>
        </Flex>

        <Box
          position="relative"
          p={4}
          bg="#FFFFFF"
          my={10}
          borderRadius="md"
          boxShadow="sm"
          _before={{
            content: '""',
            position: "absolute",
            top: "15%", // Adjust top position as needed
            zIndex: -1,
            left: -2, // Offset to align with the main box
            bottom: "20%", // Adjust bottom position as needed
            height: "70%", // Adjust height if needed
            width: "20px", // Adjust width as needed
            backgroundColor: "#F1B800", // Yellow color
            borderRadius: "10px 0 0 10px", // Curved borders on top-left and bottom-left
          }}
        >
          <Flex align="center" justifyContent="space-between">
            {/* Zones Dropdown */}
            <Flex gap={3} justifyContent="space-between">
              <Flex direction="column" gap={4}>
                <Flex align="center" gap={1}>
                  <Text variant="custom">Zone</Text>
                  <Image height="16px" src={info} opacity={0.5} />
                </Flex>
                <SelectInput
                  name="globalInput"
                  id="globalZoneInput"
                  options={zoneData}
                  customWidth={customWidth}
                  isRequired
                  value={selectedZone}
                  onChange={(selectedOptions) =>
                    handleFilter("zone", selectedOptions)
                  }
                />
              </Flex>
              <Flex direction="column" gap={4}>
                <Flex align="center" gap={1}>
                  <Text variant="custom">Markets</Text>
                  <Image height="16px" src={info} opacity={0.5} />
                </Flex>
                {/* Markets Dropdown */}
                <SelectInput
                  name="globalInput"
                  id="globalMarketsInput"
                  options={marketData}
                  customWidth={customWidth}
                  isRequired
                  value={selectedMarket}
                  onChange={(selectedOptions) =>
                    handleFilter("market", selectedOptions)
                  }
                />
              </Flex>
              <Flex direction="column" gap={4}>
                <Flex align="center" gap={1}>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    position="relative"
                    width="110px"
                    borderBottom="1px solid black"
                  >
                    <Text
                      fontSize="sm"
                      fontWeight={selected === "Y" ? "bold" : "medium"}
                      cursor="pointer"
                      onClick={() => handleSelection("Y")}
                      zIndex="1" // Ensure text is above the circle
                    >
                      Y
                    </Text>

                    <Text
                      fontSize="sm"
                      fontWeight={selected === "Q" ? "bold" : "medium"}
                      cursor="pointer"
                      onClick={() => handleSelection("Q")}
                      zIndex="1" // Ensure text is above the circle
                    >
                      Q
                    </Text>

                    <Text
                      fontSize="sm"
                      fontWeight={selected === "M" ? "bold" : "medium"}
                      cursor="pointer"
                      onClick={() => handleSelection("M")}
                      zIndex="1" // Ensure text is above the circle
                    >
                      M
                    </Text>

                    <Box
                      position="absolute"
                      top="50%"
                      left="0"
                      right="0"
                      height="1px"
                      backgroundColor="black"
                      zIndex="-1"
                    />

                    <Box
                      position="absolute"
                      top="50%"
                      left={getCirclePosition()}
                      transform="translate(-50%, -50%)"
                      border="1px solid black"
                      borderRadius="full"
                      width="16px"
                      height="25px"
                      // backgroundColor="#F1B800"
                      transition="left 0.3s ease"
                      zIndex="0" // Ensure circle is below the text
                    />
                  </Flex>
                </Flex>

                <Box>
                  <SelectInput
                    name="globalInput"
                    id="globalYearsInput"
                    options={selectedRange}
                    customWidth={customWidth}
                    isRequired
                    value={selectedYear}
                    onChange={(selectedOptions) =>
                      handleFilter("year", selectedOptions)
                    }
                  />
                </Box>
              </Flex>
              <Flex direction="column" gap={4}>
                <Flex align="center" gap={1}>
                  <Text variant="custom">Aggregate</Text>
                  <Image height="16px" src={info} opacity={0.5} />
                </Flex>
                <Box>
                  <SelectInput
                    name="globalInput"
                    id="globalAggregateInput"
                    options={aggregatedData}
                    customWidth={customWidth}
                    isRequired
                    value={selectedAggregate}
                    onChange={(selectedOptions) =>
                      handleFilter("aggregate", selectedOptions)
                    }
                  />
                </Box>
              </Flex>
              <Flex direction="column" gap={4}>
                <Flex align="center" gap={1}>
                  <Text variant="custom">Show</Text>
                  <Image height="16px" src={info} opacity={0.5} />
                </Flex>
                {/* Toggle for Percentage */}
                <Flex
                  align="center"
                  gap={2}
                  p={2}
                  border="1px solid #1414141f"
                  borderRadius="8px"
                >
                  <Text variant="custom">Percentage(%)</Text>
                  <Switch
                    size="sm"
                    colorScheme="teal"
                    isChecked={isSwitchOn}
                    onChange={handleSwitchChange}
                  />
                  <Text variant="custom">Absolute (kHL)</Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex w="20%">
              {/* Buttons */}
              <Flex direction="column" flex="1" gap={1}>
                <Button
                  variant="green"
                  border="none"
                  color="white"
                  py={4}
                  size="sm"
                >
                  <Image src={vectorback} m={1} />
                  <Text color="#FFFFFF">Export to Excel</Text>
                </Button>
                <Flex gap={4}>
                  <Button
                    variant="outline"
                    color="white"
                    size="sm"
                    py={4}
                    flex="1"
                  >
                    Reset
                  </Button>
                  <Button
                    variant="outline"
                    color="white"
                    size="sm"
                    py={4}
                    flex="1"
                  >
                    Apply
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Box>
        <Flex gap="5" direction="column">
          {innoData.map((data) => (
            <Container key={data.country}>
            <ExploreCountry data={data} isSwitchOn={isSwitchOn} />
            </Container>
          ))}
        </Flex>
      </Container>
    </>
  );
};

export default GlobalView;
