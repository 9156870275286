import {
  Box,
  Flex,
  IconButton,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Option } from "innovate/components/Inputs/MultiSelectInput";
import SelectInput from "innovate/components/Inputs/SelectInput";
import React, { useCallback, useMemo } from "react";
import {
  RxChevronLeft,
  RxChevronRight,
  RxDoubleArrowLeft,
  RxDoubleArrowRight,
} from "react-icons/rx";

interface DynamicTableProps<T> {
  data: T[];
  columns: ColumnDef<T, any>[]; // Correct type for columns
  name: string;
}

const DynamicTable = <T,>({ data, columns, name }: DynamicTableProps<T>) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const currentValue = table.getState().pagination.pageSize;
  const options = useMemo(
    () =>
      [10, 25, 50, 100, currentValue]
        .filter((v, i, a) => a.indexOf(v) === i)
        .sort((a, b) => a - b)
        .map((i) => ({ label: `${i}`, value: `${i}` })),
    [currentValue]
  );
  const toFirstPage = useCallback(() => table.setPageIndex(0), [table]);
  const toLastPage = useCallback(
    () => table.setPageIndex(table.getPageCount() - 1),
    [table]
  );
  const currentOption = useMemo(
    () => options.find((o) => o.value === `${currentValue}`)!,
    [options, currentValue]
  );
  const handleChange = useCallback(
    (o: Option | null) =>
      table.setPageSize(parseInt(o?.value ?? "", 10) ?? currentValue),
    [table, currentValue]
  );
  return (
    <>
      <TableContainer className={`${name}-container`}>
        <Table className={name}>
          {table.getHeaderGroups().map((headerGroup) => (
            <Thead className="table-header" key={headerGroup.id}>
              <Tr>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    className="header dynamicTableHeader"
                    color="#636363D6"
                    fontSize="14px"
                    textAlign="center"
                    fontWeight="bold"
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </Th>
                ))}
              </Tr>
            </Thead>
          ))}
          <Tbody>
            {data && data?.length === 0 ? (
              <Tr className="">
                <Td
                  colSpan={columns.length}
                  color="#000000D6"
                  fontSize="14px"
                  textAlign="center"
                  fontWeight="bold"
                  h={120}
                  className="cell noDataFound"
                >
                  No Data Found
                </Td>
              </Tr>
            ) : (
              table.getRowModel().rows.map((row, index) => (
                <Tr
                  className=""
                  key={row.id}
                  bgColor={
                    name === "exploreTable" && index === data.length - 1
                      ? "#F5F5F5"
                      : "#FFFFFF"
                  }
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td
                      key={cell.id}
                      className="cell"
                      color="#000000D6"
                      fontSize="14px"
                      textAlign="center"
                      fontWeight="bold"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex
        align="center"
        justify="space-between"
        px={5}
        display={name === "exploreTable" ? "none" : "flex"}
      >
        <Text fontSize="12px" fontWeight={600}>
          Showing{" "}
          {table.getState().pagination.pageSize *
            table.getState().pagination.pageIndex +
            1}
          -
          {Math.min(
            table.getState().pagination.pageSize *
              (table.getState().pagination.pageIndex + 1),
            table.getCoreRowModel().rows.length
          )}{" "}
          of {table.getCoreRowModel().rows.length} items
        </Text>
        <Spacer />
        {name !== "regionalTable" && (
          <>
            <Text
              fontSize="12px"
              fontWeight={600}
              whiteSpace="nowrap"
              marginX={2}
            >
              Rows per page
            </Text>
            <Box w="6rem">
              <SelectInput
                onChange={handleChange}
                isRequired
                name="rows-per-page"
                options={options}
                customWidth="100%"
                id="rows-per-page"
                value={currentOption}
              />
            </Box>
          </>
        )}

        <Box w="6rem" />
        <Text
          align="center"
          fontSize="12px"
          fontWeight={600}
          marginLeft={4}
          marginRight={2}
        >
          Page {table.getState().pagination.pageIndex + 1} of{" "}
          {table.getPageCount()}
        </Text>
        <IconButton
          size="md"
          variant="outline2"
          marginX={0.5}
          aria-label="Go to first page"
          icon={<RxDoubleArrowLeft />}
          onClick={toFirstPage}
          isDisabled={!table.getCanPreviousPage()}
        />
        <IconButton
          size="md"
          variant="outline2"
          marginX={0.5}
          aria-label="Go to previous page"
          icon={<RxChevronLeft />}
          onClick={table.previousPage}
          isDisabled={!table.getCanPreviousPage()}
        />
        <IconButton
          size="md"
          variant="outline2"
          marginX={0.5}
          aria-label="Go to next page"
          icon={<RxChevronRight />}
          onClick={table.nextPage}
          isDisabled={!table.getCanNextPage()}
        />
        <IconButton
          variant="outline2"
          aria-label="Go to last page"
          icon={<RxDoubleArrowRight />}
          onClick={toLastPage}
          isDisabled={!table.getCanNextPage()}
        />
      </Flex>
    </>
  );
};

export default DynamicTable;
