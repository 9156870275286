import { useAdminAuthorized } from "innovate/api/api";
import Loader from "innovate/components/common/Loader";
import withApprovalProvider from "innovate/utils/withApprovalProvider";
import { lazy, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

const UsersList = lazy(() => import("./pages/AdminPages/Users"));
const RequestAdmin = lazy(() => import("./pages/AccessRequest/RequestAdmin"));

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { isAdmin, isLoading } = useAdminAuthorized();

  const navigate = useNavigate();

  if (isLoading) {
    return <Loader dH="90vh" dW="20vw" />;
  }

  if (!isAdmin) {
    navigate("/404", {
      state: { apiEndpoint: "admin", status: "404" },
    });
  }

  return children;
};

const adminRoutes = [
  {
    path: "admin",
    children: [
      {
        index: true,
        Component: withApprovalProvider(
          <ProtectedRoute>
            <UsersList />
          </ProtectedRoute>
        ),
      },
      {
        path: "create-user",
        Component: withApprovalProvider(
          <ProtectedRoute>
            <RequestAdmin />
          </ProtectedRoute>
        ),
      },
      {
        path: "edit-user",
        Component: withApprovalProvider(
          <ProtectedRoute>
            <RequestAdmin />
          </ProtectedRoute>
        ),
      },
    ],
  },
];

export default adminRoutes;
