import {
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { CellContext } from "@tanstack/react-table";
import Brazil from "innovate/assets/flags/Brazil.svg";
import SA from "innovate/assets/flags/SouthAfrica.svg";
import vector from "innovate/assets/images/select_all.png";
import shareImage from "innovate/assets/images/share.svg";
import MultiSelectInput from "innovate/components/Inputs/MultiSelectInput";
import { ScenarioType, SharePopoverProps } from "innovate/utils/types";
import React from "react";

const CheckboxHeader = () => <Image src={vector} m={1} />;
const IncMaCoHeader = () => (
  <Box className="header-content incremental-maco-header">
    Incremental MaCo ('000 USD)
  </Box>
);

const formatDate: React.FC<CellContext<ScenarioType, unknown>> = ({
  getValue,
}) => {
  const value = getValue();
  // You may need to assert the value type if you are sure it's a date string or timestamp
  const date = new Date(value as string | number);

  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",
    year: "2-digit",
  };

  return <p>{date.toLocaleDateString("en-GB", options).replace(/ /g, "-")}</p>;
};

const TableCell: React.FC<CellContext<ScenarioType, unknown>> = ({
  getValue,
  column,
}) => {
  const value = getValue() as ScenarioType[keyof ScenarioType];
  const header = column?.columnDef?.header;
  if (header === "Total Volume" || header === "Incremental Volume") {
    return <Text>{value} HL</Text>;
  }
  if (header === "Country") {
    let imageUrl = "";
    let title = "";
    if (value === "South Africa") {
      imageUrl = SA;
      title = "South Africa";
    } else {
      imageUrl = Brazil;
      title = "Brazil";
    }
    return (
      <Center>
        <Tooltip label={title} hasArrow>
          <Image boxSize="33px" src={imageUrl} alt={header} />
        </Tooltip>{" "}
      </Center>
    );
  }

  return <Text>{value}</Text>;
};

const SharePopover: React.FC<SharePopoverProps> = ({
  isOpen,
  onClose,
  userOptions,
  userValue,
  handleSelectChange,
  handleShare,
  openShareList,
  rowData,
}) => (
  <Popover isOpen={isOpen} onClose={onClose}>
    <PopoverTrigger>
      <IconButton
        pl="12px"
        title="Share the simulation"
        aria-label="share"
        icon={<Image src={shareImage} alt="share" />}
        onClick={() => {
          openShareList(rowData);
        }}
        bg="none"
        _hover={{ bg: "none" }}
        _active={{ bg: "none" }}
        minW="auto"
        h="auto"
      />
    </PopoverTrigger>
    <PopoverContent w="290px">
      <PopoverBody p={4}>
        <Flex mb={2} alignItems="center" justifyContent="center">
          <Image src={shareImage} />
          <Text color="black" ml={2}>
            Share Scenario
          </Text>
        </Flex>

        <Flex alignItems="center" justifyContent="center" gap={1}>
          <MultiSelectInput
            name="shareUser"
            id="shareUser"
            options={userOptions}
            value={userValue}
            onChange={(selectedOptions) => handleSelectChange(selectedOptions)}
            placeholder="Select User"
          />
          <Button
            variant="outline"
            bg="#191919"
            onClick={() => {
              handleShare();
            }}
          >
            <Text color="#FFFFFF">Share</Text>
          </Button>
        </Flex>
      </PopoverBody>
    </PopoverContent>
  </Popover>
);

const handleCheckboxSelect = (
  row: ScenarioType,
  isChecked: boolean,
  checkedRows: ScenarioType[],
  setCheckedRows: React.Dispatch<React.SetStateAction<ScenarioType[]>>,
  setCompareId: React.Dispatch<React.SetStateAction<number[]>>,
  showToast: (
    title: string,
    description: string,
    status: "error" | "warning"
  ) => void
) => {
  const { is_range: isRange } = row; // Rename to camel case using destructuring

  if (isRange) {
    showToast(
      "Selection not allowed",
      "Sorry, scenarios with pack size ranges cannot be compared!",
      "error"
    );
    return;
  }

  if (isChecked) {
    if (checkedRows.length < 2) {
      setCheckedRows([...checkedRows, row]);
      setCompareId((prevState) => [...prevState, row.id]);
    } else {
      showToast(
        "Limit Exceeded",
        "You can only check two checkboxes.",
        "warning"
      );
    }
  } else {
    setCheckedRows(checkedRows.filter((checkedRow) => checkedRow !== row));
    setCompareId((prevState) => prevState.filter((id) => id !== row.id));
  }
};
const removeSimulationById = (
  setSimulations: React.Dispatch<React.SetStateAction<ScenarioType[]>>,
  id: number
) => {
  setSimulations((prevSimulations) =>
    prevSimulations.filter((simulation) => simulation.id !== id)
  );
};

const updateSimulation = (
  setSimulations: React.Dispatch<React.SetStateAction<ScenarioType[]>>,
  updatedSimulation: ScenarioType
) => {
  setSimulations((prevSimulations) =>
    prevSimulations.map((simulation) =>
      simulation.id === updatedSimulation.id
        ? { ...simulation, name: updatedSimulation.name }
        : simulation
    )
  );
};

export {
  CheckboxHeader,
  IncMaCoHeader,
  TableCell,
  formatDate,
  SharePopover,
  handleCheckboxSelect,
  removeSimulationById,
  updateSimulation,
};
