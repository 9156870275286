// sonarignore
import { Text } from "@chakra-ui/react";
import { ColumnDef, Row } from "@tanstack/react-table";
import {
  CheckboxHeader,
  formatDate,
  IncMaCoHeader,
  TableCell,
} from "innovate/utils/PreLaunch/PreLaunchHelper";
import { ScenarioType, UserData } from "innovate/utils/types";

export const clientId =
  import.meta.env.VITE_CLIENT_ID ?? "5d7c1d57-b6fd-4f46-bd7c-c9589ebdfef1";
export const redirectUri =
  import.meta.env.VITE_REDIRECT_URI ?? "https://innovatex.ai-stg.ab-inbev.com";
export const fixed =
  import.meta.env.VITE_API_URL ?? "https://dev.innox.ab-inbev.com/api/";

type CellComponent = (props: { row: Row<ScenarioType> }) => JSX.Element;

export const categoryDistributionRSA = { BEER: 6.11, "BEYOND BEER": 6.15 };
export const categoryDistributionBR = {
  BEER: 4.9,
  "BEYOND BEER": 22.8,
  NAB: 21.8,
  "NON ALC BEER": 5.9,
};

export const updatedExplorePer = [
  {
    country: "COL",
    zone: "MAZ",
    abi: [
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0.8%",
              },
              {
                type: "CAN",
                value: "1.6%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "61.2%",
              },
              {
                type: "CAN",
                value: "9.8%",
              },
              {
                type: "NRB",
                value: "0.1%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "23.4%",
              },
              {
                type: "CAN",
                value: "3.1%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "5.6%",
              },
              {
                type: "CAN",
                value: "29.5%",
              },
              {
                type: "NRB",
                value: "44.5%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "4.9%",
              },
              {
                type: "CAN",
                value: "0.1%",
              },
              {
                type: "NRB",
                value: "15.5%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "VALUE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "36.0%",
              },
              {
                type: "CAN",
                value: "64.0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "AGUILA",
        segment: "CORE",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0.2%",
              },
              {
                type: "CAN",
                value: "1.2%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "69.0%",
              },
              {
                type: "CAN",
                value: "9.6%",
              },
              {
                type: "NRB",
                value: "0.2%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "18.0%",
              },
              {
                type: "CAN",
                value: "1.8%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "AZTECA",
        segment: "VALUE",
        "Brand Category": "TAIL",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "100.0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "BAHIA",
        segment: "VALUE",
        "Brand Category": "TAIL",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "100.0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "BUDWEISER",
        segment: "P / SP",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "5.9%",
              },
              {
                type: "CAN",
                value: "78.3%",
              },
              {
                type: "NRB",
                value: "15.5%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.3%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CORONA",
        segment: "P / SP",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "5.9%",
              },
              {
                type: "CAN",
                value: "5.2%",
              },
              {
                type: "NRB",
                value: "62.4%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "7.7%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "18.8%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "NATIVA",
        segment: "VALUE",
        "Brand Category": "SUSTAIN",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "100.0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "PILSEN",
        segment: "CORE",
        "Brand Category": "SUSTAIN",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "44.1%",
              },
              {
                type: "CAN",
                value: "5.2%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "37.3%",
              },
              {
                type: "CAN",
                value: "13.4%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "POKER",
        segment: "CORE",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "1.9%",
              },
              {
                type: "CAN",
                value: "2.4%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "49.8%",
              },
              {
                type: "CAN",
                value: "10.7%",
              },
              {
                type: "NRB",
                value: "0.1%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "30.8%",
              },
              {
                type: "CAN",
                value: "4.2%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "STELLA ARTOIS",
        segment: "P / SP",
        "Brand Category": "EXPANSION",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "21.1%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "78.9%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
    ],
    "heineken beverages": [
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "1.4%",
              },
              {
                type: "CAN",
                value: "4.3%",
              },
              {
                type: "NRB",
                value: "2.5%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "29.9%",
              },
              {
                type: "CAN",
                value: "10.6%",
              },
              {
                type: "NRB",
                value: "12.5%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "24.7%",
              },
              {
                type: "CAN",
                value: "14.1%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "1.2%",
              },
              {
                type: "CAN",
                value: "26.0%",
              },
              {
                type: "NRB",
                value: "34.5%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0.2%",
              },
              {
                type: "CAN",
                value: "26.3%",
              },
              {
                type: "NRB",
                value: "7.2%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "4.7%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "ANDINA",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "2.7%",
              },
              {
                type: "CAN",
                value: "6.6%",
              },
              {
                type: "NRB",
                value: "3.7%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "22.9%",
              },
              {
                type: "CAN",
                value: "13.2%",
              },
              {
                type: "NRB",
                value: "3.9%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "20.4%",
              },
              {
                type: "CAN",
                value: "26.6%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "HEINEKEN",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "1.2%",
              },
              {
                type: "CAN",
                value: "26.0%",
              },
              {
                type: "NRB",
                value: "34.5%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0.2%",
              },
              {
                type: "CAN",
                value: "26.3%",
              },
              {
                type: "NRB",
                value: "7.2%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "4.7%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "MILLER",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "5.8%",
              },
              {
                type: "NRB",
                value: "4.7%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "9.9%",
              },
              {
                type: "NRB",
                value: "79.7%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "TECATE",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.2%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "51.1%",
              },
              {
                type: "CAN",
                value: "7.0%",
              },
              {
                type: "NRB",
                value: "1.9%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "39.8%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
    ],
    "total market": [
      {
        type: "CORE",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0.8%",
              },
              {
                type: "CAN",
                value: "1.8%",
              },
              {
                type: "NRB",
                value: "0.2%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "59.0%",
              },
              {
                type: "CAN",
                value: "9.9%",
              },
              {
                type: "NRB",
                value: "1.0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "23.5%",
              },
              {
                type: "CAN",
                value: "3.9%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "1.1%",
              },
              {
                type: "CAN",
                value: "3.9%",
              },
              {
                type: "NRB",
                value: "3.6%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "53.8%",
              },
              {
                type: "CAN",
                value: "11.3%",
              },
              {
                type: "NRB",
                value: "2.0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "20.7%",
              },
              {
                type: "CAN",
                value: "3.5%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "P / SP",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "5.0%",
              },
              {
                type: "CAN",
                value: "28.9%",
              },
              {
                type: "NRB",
                value: "43.1%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "4.2%",
              },
              {
                type: "CAN",
                value: "3.9%",
              },
              {
                type: "NRB",
                value: "14.2%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.7%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "VALUE",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "36.0%",
              },
              {
                type: "CAN",
                value: "64.0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    country: "MEX",
    zone: "MAZ",
    abi: [
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "4.4%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0.4%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "15.3%",
              },
              {
                type: "CAN",
                value: "13.2%",
              },
              {
                type: "NRB",
                value: "0.4%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "49.4%",
              },
              {
                type: "CAN",
                value: "16.7%",
              },
              {
                type: "NRB",
                value: "0.3%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.2%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "8.4%",
              },
              {
                type: "CAN",
                value: "44.0%",
              },
              {
                type: "NRB",
                value: "1.7%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "13.3%",
              },
              {
                type: "CAN",
                value: "32.4%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "1.4%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "27.3%",
              },
              {
                type: "CAN",
                value: "27.4%",
              },
              {
                type: "NRB",
                value: "25.6%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "5.4%",
              },
              {
                type: "CAN",
                value: "12.9%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "VALUE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0.5%",
              },
              {
                type: "CAN",
                value: "13.4%",
              },
              {
                type: "NRB",
                value: "86.1%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "BARRILITO",
        segment: "VALUE",
        "Brand Category": "SUSTAIN",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0.5%",
              },
              {
                type: "CAN",
                value: "13.4%",
              },
              {
                type: "NRB",
                value: "86.1%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "BUD LIGHT",
        segment: "CORE",
        "Brand Category": "SUSTAIN",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "72.3%",
              },
              {
                type: "NRB",
                value: "2.6%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "12.3%",
              },
              {
                type: "CAN",
                value: "12.8%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CORONA",
        segment: "CORE",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "5.7%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0.1%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "15.2%",
              },
              {
                type: "CAN",
                value: "15.3%",
              },
              {
                type: "NRB",
                value: "0.3%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "55.8%",
              },
              {
                type: "CAN",
                value: "7.6%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "ESTRELLA",
        segment: "CORE",
        "Brand Category": "TAIL",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "1.7%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "2.9%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0.1%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "19.3%",
              },
              {
                type: "CAN",
                value: "76.0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "LEON",
        segment: "CORE",
        "Brand Category": "TAIL",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "10.4%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "89.6%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "MICHELOB",
        segment: "PREMIUM",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "1.5%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "30.0%",
              },
              {
                type: "CAN",
                value: "29.6%",
              },
              {
                type: "NRB",
                value: "20.4%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "4.3%",
              },
              {
                type: "CAN",
                value: "14.2%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "MODELO",
        segment: "CORE+",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.2%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "8.4%",
              },
              {
                type: "CAN",
                value: "44.0%",
              },
              {
                type: "NRB",
                value: "1.7%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "13.3%",
              },
              {
                type: "CAN",
                value: "32.4%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "PACIFICO",
        segment: "CORE",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "6.1%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "14.4%",
              },
              {
                type: "CAN",
                value: "34.7%",
              },
              {
                type: "NRB",
                value: "2.3%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "32.3%",
              },
              {
                type: "CAN",
                value: "4.4%",
              },
              {
                type: "NRB",
                value: "5.9%",
              },
            ],
          },
        ],
      },
      {
        type: "STELLA ARTOIS",
        segment: "PREMIUM",
        "Brand Category": "EXPANSION",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "5.0%",
              },
              {
                type: "NRB",
                value: "78.3%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "16.7%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "VICTORIA",
        segment: "CORE",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "3.0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0.9%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "17.8%",
              },
              {
                type: "CAN",
                value: "2.5%",
              },
              {
                type: "NRB",
                value: "0.1%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "47.6%",
              },
              {
                type: "CAN",
                value: "28.1%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
    ],
    "heineken beverages": [
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "7.8%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "5.7%",
              },
              {
                type: "CAN",
                value: "27.6%",
              },
              {
                type: "NRB",
                value: "1.2%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "32.9%",
              },
              {
                type: "CAN",
                value: "22.1%",
              },
              {
                type: "NRB",
                value: "2.6%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "10.4%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "13.8%",
              },
              {
                type: "CAN",
                value: "9.0%",
              },
              {
                type: "NRB",
                value: "6.0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "51.3%",
              },
              {
                type: "CAN",
                value: "8.6%",
              },
              {
                type: "NRB",
                value: "0.8%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "7.6%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "7.8%",
              },
              {
                type: "CAN",
                value: "30.8%",
              },
              {
                type: "NRB",
                value: "35.0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "6.8%",
              },
              {
                type: "CAN",
                value: "12.0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "VALUE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "3.4%",
              },
              {
                type: "CAN",
                value: "43.4%",
              },
              {
                type: "NRB",
                value: "4.3%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "39.0%",
              },
              {
                type: "CAN",
                value: "9.7%",
              },
              {
                type: "NRB",
                value: "0.3%",
              },
            ],
          },
        ],
      },
      {
        type: "AMSTEL",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "15.0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "38.6%",
              },
              {
                type: "NRB",
                value: "42.9%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "3.5%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "BOHEMIA",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "32.5%",
              },
              {
                type: "CAN",
                value: "11.1%",
              },
              {
                type: "NRB",
                value: "47.1%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "9.3%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CARTA BLANCA",
        segment: "VALUE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "1.2%",
              },
              {
                type: "CAN",
                value: "31.6%",
              },
              {
                type: "NRB",
                value: "6.7%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "45.0%",
              },
              {
                type: "CAN",
                value: "15.1%",
              },
              {
                type: "NRB",
                value: "0.4%",
              },
            ],
          },
        ],
      },
      {
        type: "COORS",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "99.2%",
              },
              {
                type: "NRB",
                value: "0.8%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "DOS EQUIS",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "10.4%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "13.8%",
              },
              {
                type: "CAN",
                value: "9.0%",
              },
              {
                type: "NRB",
                value: "6.0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "51.3%",
              },
              {
                type: "CAN",
                value: "8.6%",
              },
              {
                type: "NRB",
                value: "0.8%",
              },
            ],
          },
        ],
      },
      {
        type: "HEINEKEN",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "4.5%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "35.5%",
              },
              {
                type: "NRB",
                value: "18.8%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "18.4%",
              },
              {
                type: "CAN",
                value: "22.7%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "INDIO",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "7.6%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "10.1%",
              },
              {
                type: "CAN",
                value: "9.9%",
              },
              {
                type: "NRB",
                value: "3.8%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "49.0%",
              },
              {
                type: "CAN",
                value: "18.2%",
              },
              {
                type: "NRB",
                value: "1.3%",
              },
            ],
          },
        ],
      },
      {
        type: "MILLER",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "19.5%",
              },
              {
                type: "NRB",
                value: "7.9%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "33.5%",
              },
              {
                type: "NRB",
                value: "39.1%",
              },
            ],
          },
        ],
      },
      {
        type: "SOL",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "2.0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "13.7%",
              },
              {
                type: "CAN",
                value: "0.6%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "12.3%",
              },
              {
                type: "CAN",
                value: "71.4%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "SUPERIOR",
        segment: "VALUE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "7.4%",
              },
              {
                type: "CAN",
                value: "64.5%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "28.0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "TECATE",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "9.2%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "4.7%",
              },
              {
                type: "CAN",
                value: "33.3%",
              },
              {
                type: "NRB",
                value: "0.2%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "34.8%",
              },
              {
                type: "CAN",
                value: "17.8%",
              },
              {
                type: "NRB",
                value: "0.1%",
              },
            ],
          },
        ],
      },
    ],
    "total market": [
      {
        type: "CORE",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "5.6%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0.3%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "12.1%",
              },
              {
                type: "CAN",
                value: "17.9%",
              },
              {
                type: "NRB",
                value: "0.6%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "43.9%",
              },
              {
                type: "CAN",
                value: "18.5%",
              },
              {
                type: "NRB",
                value: "1.1%",
              },
            ],
          },
        ],
      },
      {
        type: "CORE+",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "3.6%",
              },
              {
                type: "CAN",
                value: "0.1%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "10.3%",
              },
              {
                type: "CAN",
                value: "32.1%",
              },
              {
                type: "NRB",
                value: "3.1%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "26.3%",
              },
              {
                type: "CAN",
                value: "24.3%",
              },
              {
                type: "NRB",
                value: "0.3%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "4.6%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0.4%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "11.1%",
              },
              {
                type: "CAN",
                value: "21.6%",
              },
              {
                type: "NRB",
                value: "5.1%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "38.3%",
              },
              {
                type: "CAN",
                value: "18.1%",
              },
              {
                type: "NRB",
                value: "0.8%",
              },
            ],
          },
        ],
      },
      {
        type: "PREMIUM",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "5.3%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "15.0%",
              },
              {
                type: "CAN",
                value: "29.6%",
              },
              {
                type: "NRB",
                value: "31.5%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "6.3%",
              },
              {
                type: "CAN",
                value: "12.3%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "VALUE",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "2.2%",
              },
              {
                type: "CAN",
                value: "31.6%",
              },
              {
                type: "NRB",
                value: "36.5%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "23.6%",
              },
              {
                type: "CAN",
                value: "5.9%",
              },
              {
                type: "NRB",
                value: "0.2%",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    country: "KOR",
    zone: "APAC",
    abi: [
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.9%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "8.9%",
              },
              {
                type: "CAN",
                value: "38.6%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "3.6%",
              },
              {
                type: "CAN",
                value: "48.1%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "3.1%",
              },
              {
                type: "CAN",
                value: "6.6%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "90.3%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "BUDWEISER",
        segment: "PREMIUM",
        "Brand Category": "SUSTAIN",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "5.5%",
              },
              {
                type: "CAN",
                value: "7.1%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "87.4%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CASS",
        segment: "CORE",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.9%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "9.0%",
              },
              {
                type: "CAN",
                value: "38.8%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "3.7%",
              },
              {
                type: "CAN",
                value: "47.6%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "HANMAC",
        segment: "CORE",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "26.8%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "73.2%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "HOEGAARDEN",
        segment: "PREMIUM",
        "Brand Category": "SUSTAIN",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0.3%",
              },
              {
                type: "CAN",
                value: "8.0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "91.7%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "STELLA ARTOIS",
        segment: "PREMIUM",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.1%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0.3%",
              },
              {
                type: "CAN",
                value: "4.6%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "95.1%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
    ],
    bk: [
      {
        type: "Default",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.9%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0.1%",
              },
              {
                type: "CAN",
                value: "9.2%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "7.5%",
              },
              {
                type: "CAN",
                value: "82.3%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "TSINGTAO",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.9%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0.1%",
              },
              {
                type: "CAN",
                value: "9.2%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "7.5%",
              },
              {
                type: "CAN",
                value: "82.3%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
    ],
    diageo: [
      {
        type: "Default",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "100.0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "GUINNESS",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "100.0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
    ],
    "heineken beverages": [
      {
        type: "Default",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0.8%",
              },
              {
                type: "CAN",
                value: "14.4%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "84.8%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "HEINEKEN",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0.8%",
              },
              {
                type: "CAN",
                value: "14.4%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "84.8%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
    ],
    "hite jinro": [
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "1.0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "9.4%",
              },
              {
                type: "CAN",
                value: "32.6%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "56.9%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "5.6%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "94.4%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "HITE",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "11.1%",
              },
              {
                type: "CAN",
                value: "54.8%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "33.9%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "KELLY",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "11.5%",
              },
              {
                type: "CAN",
                value: "30.6%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "57.9%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "KIRIN ICHIBAN",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "5.3%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "94.7%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "KRONENBOURG",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "6.0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "94.0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "TERRA",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "1.7%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "8.1%",
              },
              {
                type: "CAN",
                value: "29.2%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "61.0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
    ],
    "lotte chilsung": [
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "11.2%",
              },
              {
                type: "CAN",
                value: "20.3%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "68.5%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "KLOUD",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "11.2%",
              },
              {
                type: "CAN",
                value: "20.3%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "68.5%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
    ],
    "lotteasahijuryu imp": [
      {
        type: "Default",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "46.4%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1.7%",
              },
              {
                type: "CAN",
                value: "51.9%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "ASAHI",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "46.4%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1.7%",
              },
              {
                type: "CAN",
                value: "51.9%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
    ],
    "total market": [
      {
        type: "CORE",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.8%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "9.2%",
              },
              {
                type: "CAN",
                value: "35.7%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "2.4%",
              },
              {
                type: "CAN",
                value: "51.9%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.7%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "7.4%",
              },
              {
                type: "CAN",
                value: "32.0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "2.1%",
              },
              {
                type: "CAN",
                value: "57.9%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "PREMIUM",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.1%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "1.1%",
              },
              {
                type: "CAN",
                value: "19.1%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1.1%",
              },
              {
                type: "CAN",
                value: "78.6%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    country: "SAF",
    zone: "SAF",
    abi: [
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.1%",
              },
              {
                type: "NRB",
                value: "1.9%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "91.0%",
              },
              {
                type: "CAN",
                value: "7.0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.1%",
              },
              {
                type: "NRB",
                value: "9.4%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "68.6%",
              },
              {
                type: "CAN",
                value: "21.9%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "78.5%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "8.9%",
              },
              {
                type: "NRB",
                value: "12.6%",
              },
            ],
          },
        ],
      },
      {
        type: "BUDWEISER",
        segment: "P / SP",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "100%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CARLING BLACK LABEL",
        segment: "CORE",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "1.6%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "92.2%",
              },
              {
                type: "CAN",
                value: "6.2%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE DOUBLE MALT",
        segment: "CORE+",
        "Brand Category": "Expansion",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "7.1%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "70.9%",
              },
              {
                type: "CAN",
                value: "22.0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE LAGER",
        segment: "CORE",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.1%",
              },
              {
                type: "NRB",
                value: "2.6%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "90%",
              },
              {
                type: "CAN",
                value: "7.3%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE LITE",
        segment: "CORE+",
        "Brand Category": "Sustain",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.2%",
              },
              {
                type: "NRB",
                value: "14.8%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "54.3%",
              },
              {
                type: "CAN",
                value: "30.6%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE MILK STOUT",
        segment: "CORE+",
        "Brand Category": "Sustain",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "1.9%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "88.9%",
              },
              {
                type: "CAN",
                value: "9.2%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CORONA",
        segment: "P / SP",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "93.4%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "6.6%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "HANSA PILSNER",
        segment: "CORE",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "4.5%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "81.0%",
              },
              {
                type: "CAN",
                value: "14.5%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "LION LAGER",
        segment: "CORE",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "84.5%",
              },
              {
                type: "CAN",
                value: "15.5%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "STELLA ARTOIS",
        segment: "P / SP",
        "Brand Category": "Expansion",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "37.4%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "15.4%",
              },
              {
                type: "NRB",
                value: "47.3%",
              },
            ],
          },
        ],
      },
    ],
    "heineken beverages": [
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "43.9%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "56.1%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.1%",
              },
              {
                type: "NRB",
                value: "14.7%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "63.3%",
              },
              {
                type: "CAN",
                value: "20.2%",
              },
              {
                type: "NRB",
                value: "1.7%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.1%",
              },
              {
                type: "NRB",
                value: "20.1%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "45.3%",
              },
              {
                type: "CAN",
                value: "16.2%",
              },
              {
                type: "NRB",
                value: "18.3%",
              },
            ],
          },
        ],
      },
      {
        type: "AMSTEL",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.2%",
              },
              {
                type: "NRB",
                value: "4.5%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "80.4%",
              },
              {
                type: "CAN",
                value: "11.2%",
              },
              {
                type: "NRB",
                value: "3.7%",
              },
            ],
          },
        ],
      },
      {
        type: "HEINEKEN",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.1%",
              },
              {
                type: "NRB",
                value: "17.1%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "47.1%",
              },
              {
                type: "CAN",
                value: "16.7%",
              },
              {
                type: "NRB",
                value: "19.0%",
              },
            ],
          },
        ],
      },
      {
        type: "SOL",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "97.2%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "2.8%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "SOWETO GOLD",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "100%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "TAFEL",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "97.7%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "2.3%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "WINDHOEK",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "24.0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "47.7%",
              },
              {
                type: "CAN",
                value: "28.3%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
    ],
    "total market": [
      {
        type: "CORE",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.1%",
              },
              {
                type: "NRB",
                value: "2.0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "91.0%",
              },
              {
                type: "CAN",
                value: "7.0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CORE+",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.1%",
              },
              {
                type: "NRB",
                value: "10.9%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "67.1%",
              },
              {
                type: "CAN",
                value: "21.4%",
              },
              {
                type: "NRB",
                value: "0.5%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.1%",
              },
              {
                type: "NRB",
                value: "9.1%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "77.4%",
              },
              {
                type: "CAN",
                value: "11.3%",
              },
              {
                type: "NRB",
                value: "2.1%",
              },
            ],
          },
        ],
      },
      {
        type: "P / SP",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0.1%",
              },
              {
                type: "NRB",
                value: "42.5%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "27.9%",
              },
              {
                type: "CAN",
                value: "13.4%",
              },
              {
                type: "NRB",
                value: "16.1%",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    country: "BRA",
    zone: "SAZ",
    abi: [
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "8.8%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "40.2%",
              },
              {
                type: "CAN",
                value: "22.5%",
              },
              {
                type: "NRB",
                value: "0.4%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "13.1%",
              },
              {
                type: "CAN",
                value: "15.0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "9.7%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "8.9%",
              },
              {
                type: "CAN",
                value: "23.4%",
              },
              {
                type: "NRB",
                value: "30.7%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "14.2%",
              },
              {
                type: "CAN",
                value: "13.2%",
              },
              {
                type: "NRB",
                value: "0.1%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "11.5%",
              },
              {
                type: "NRB",
                value: "0.1%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "48.6%",
              },
              {
                type: "CAN",
                value: "14.8%",
              },
              {
                type: "NRB",
                value: "12.2%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "2.8%",
              },
              {
                type: "CAN",
                value: "10.0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "ANTARCTICA",
        segment: "CORE",
        "Brand Category": "SUSTAIN",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "7.1%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "42.0%",
              },
              {
                type: "CAN",
                value: "20.2%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "16.8%",
              },
              {
                type: "CAN",
                value: "13.9%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "BRAHMA",
        segment: "CORE",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "6.2%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "40.1%",
              },
              {
                type: "CAN",
                value: "19.7%",
              },
              {
                type: "NRB",
                value: "0.8%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "12.9%",
              },
              {
                type: "CAN",
                value: "20.4%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "BUDWEISER",
        segment: "CORE+",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "9.7%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "8.9%",
              },
              {
                type: "CAN",
                value: "23.4%",
              },
              {
                type: "NRB",
                value: "30.7%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "14.2%",
              },
              {
                type: "CAN",
                value: "13.2%",
              },
              {
                type: "NRB",
                value: "0.1%",
              },
            ],
          },
        ],
      },
      {
        type: "ORIGINAL",
        segment: "P / SP",
        "Brand Category": "EXPANSION",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "16.9%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "51.3%",
              },
              {
                type: "CAN",
                value: "14.0%",
              },
              {
                type: "NRB",
                value: "3.4%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "4.9%",
              },
              {
                type: "CAN",
                value: "9.6%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "SKOL",
        segment: "CORE",
        "Brand Category": "SUSTAIN",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "13.3%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "39.2%",
              },
              {
                type: "CAN",
                value: "27.7%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "11.3%",
              },
              {
                type: "CAN",
                value: "8.6%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "SPATEN",
        segment: "P / SP",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "5.1%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "45.8%",
              },
              {
                type: "CAN",
                value: "22.4%",
              },
              {
                type: "NRB",
                value: "16.5%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "10.2%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "STELLA ARTOIS",
        segment: "P / SP",
        "Brand Category": "EXPANSION",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "3.0%",
              },
              {
                type: "NRB",
                value: "0.7%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "43.5%",
              },
              {
                type: "CAN",
                value: "5.9%",
              },
              {
                type: "NRB",
                value: "35.9%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "11.0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
    ],
    "cidade imperial petropolis": [
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "12.4%",
              },
              {
                type: "NRB",
                value: "6.5%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "1.0%",
              },
              {
                type: "CAN",
                value: "28.9%",
              },
              {
                type: "NRB",
                value: "24.0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "27.2%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "IMPERIO",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "12.4%",
              },
              {
                type: "NRB",
                value: "6.5%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "1.0%",
              },
              {
                type: "CAN",
                value: "28.9%",
              },
              {
                type: "NRB",
                value: "24.0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "27.2%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
    ],
    "heineken beverages": [
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "15.8%",
              },
              {
                type: "NRB",
                value: "0.6%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "10.6%",
              },
              {
                type: "CAN",
                value: "44.0%",
              },
              {
                type: "NRB",
                value: "12.4%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "4.4%",
              },
              {
                type: "CAN",
                value: "12.2%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "6.9%",
              },
              {
                type: "NRB",
                value: "2.7%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "28.5%",
              },
              {
                type: "CAN",
                value: "17.1%",
              },
              {
                type: "NRB",
                value: "32.7%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "12.0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "VALUE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "1.0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "20.0%",
              },
              {
                type: "CAN",
                value: "30.6%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "13.9%",
              },
              {
                type: "CAN",
                value: "34.5%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "AMSTEL",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "18.9%",
              },
              {
                type: "NRB",
                value: "0.7%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "5.3%",
              },
              {
                type: "CAN",
                value: "42.5%",
              },
              {
                type: "NRB",
                value: "15.6%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "3.3%",
              },
              {
                type: "CAN",
                value: "13.5%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "DEVASSA",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "3.5%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "31.2%",
              },
              {
                type: "CAN",
                value: "49.6%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "8.7%",
              },
              {
                type: "CAN",
                value: "7.1%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "HEINEKEN",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "6.9%",
              },
              {
                type: "NRB",
                value: "2.7%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "28.5%",
              },
              {
                type: "CAN",
                value: "17.1%",
              },
              {
                type: "NRB",
                value: "32.7%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "12.0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "KAISER",
        segment: "VALUE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "20.9%",
              },
              {
                type: "CAN",
                value: "33.5%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1.4%",
              },
              {
                type: "CAN",
                value: "44.2%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "SCHIN",
        segment: "VALUE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "2.1%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "19.1%",
              },
              {
                type: "CAN",
                value: "27.7%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "26.2%",
              },
              {
                type: "CAN",
                value: "24.9%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
    ],
    petropolis: [
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "13.0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "20.8%",
              },
              {
                type: "CAN",
                value: "35.4%",
              },
              {
                type: "NRB",
                value: "2.2%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "2.5%",
              },
              {
                type: "CAN",
                value: "26.1%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "VALUE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "9.0%",
              },
              {
                type: "NRB",
                value: "0.7%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "19.7%",
              },
              {
                type: "CAN",
                value: "39.8%",
              },
              {
                type: "NRB",
                value: "0.6%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "10.3%",
              },
              {
                type: "CAN",
                value: "19.8%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CRYSTAL",
        segment: "VALUE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "3.2%",
              },
              {
                type: "NRB",
                value: "1.8%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "30.0%",
              },
              {
                type: "CAN",
                value: "48.4%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "12.8%",
              },
              {
                type: "CAN",
                value: "3.7%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "ITAIPAVA",
        segment: "VALUE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "12.1%",
              },
              {
                type: "NRB",
                value: "0.5%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "16.1%",
              },
              {
                type: "CAN",
                value: "37.2%",
              },
              {
                type: "NRB",
                value: "0.9%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "10.6%",
              },
              {
                type: "CAN",
                value: "22.6%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "LOKAL BIER",
        segment: "VALUE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "25.1%",
              },
              {
                type: "CAN",
                value: "41.6%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "4.6%",
              },
              {
                type: "CAN",
                value: "28.7%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "PETRA",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "13.0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "20.8%",
              },
              {
                type: "CAN",
                value: "35.4%",
              },
              {
                type: "NRB",
                value: "2.2%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "2.5%",
              },
              {
                type: "CAN",
                value: "26.1%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
    ],
    "total market": [
      {
        type: "CORE",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "10.0%",
              },
              {
                type: "NRB",
                value: "0.4%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "34.1%",
              },
              {
                type: "CAN",
                value: "25.9%",
              },
              {
                type: "NRB",
                value: "3.0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "11.1%",
              },
              {
                type: "CAN",
                value: "15.6%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CORE+",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "9.7%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "8.9%",
              },
              {
                type: "CAN",
                value: "23.4%",
              },
              {
                type: "NRB",
                value: "30.7%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "14.2%",
              },
              {
                type: "CAN",
                value: "13.2%",
              },
              {
                type: "NRB",
                value: "0.1%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "9.2%",
              },
              {
                type: "NRB",
                value: "0.6%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "31.6%",
              },
              {
                type: "CAN",
                value: "25.7%",
              },
              {
                type: "NRB",
                value: "7.5%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "9.3%",
              },
              {
                type: "CAN",
                value: "16.1%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "P / SP",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "9.0%",
              },
              {
                type: "NRB",
                value: "1.6%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "37.5%",
              },
              {
                type: "CAN",
                value: "16.1%",
              },
              {
                type: "NRB",
                value: "23.5%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1.3%",
              },
              {
                type: "CAN",
                value: "11.1%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "VALUE",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "6.6%",
              },
              {
                type: "NRB",
                value: "0.5%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "19.8%",
              },
              {
                type: "CAN",
                value: "37.0%",
              },
              {
                type: "NRB",
                value: "0.4%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "11.4%",
              },
              {
                type: "CAN",
                value: "24.4%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
    ],
  },
];

export const updatedExplorePerWithPercentage = updatedExplorePer;

export const updatedExploreAbs = [
  {
    country: "COL",
    zone: "MAZ",
    abi: [
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "116.2",
              },
              {
                type: "CAN",
                value: "231.4",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "8989.6",
              },
              {
                type: "CAN",
                value: "1442.5",
              },
              {
                type: "NRB",
                value: "21.2",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "3434.6",
              },
              {
                type: "CAN",
                value: "462.1",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "69.1",
              },
              {
                type: "CAN",
                value: "362.6",
              },
              {
                type: "NRB",
                value: "548.1",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "59.9",
              },
              {
                type: "CAN",
                value: "1.2",
              },
              {
                type: "NRB",
                value: "190.3",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "VALUE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "230.4",
              },
              {
                type: "CAN",
                value: "408.9",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "AGUILA",
        segment: "CORE",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "18.6",
              },
              {
                type: "CAN",
                value: "107.2",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "6126.1",
              },
              {
                type: "CAN",
                value: "853.7",
              },
              {
                type: "NRB",
                value: "16.4",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1601.1",
              },
              {
                type: "CAN",
                value: "158.6",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "AZTECA",
        segment: "VALUE",
        "Brand Category": "TAIL",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "126.2",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "BAHIA",
        segment: "VALUE",
        "Brand Category": "TAIL",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "282.7",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "BUDWEISER",
        segment: "P / SP",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "23.3",
              },
              {
                type: "CAN",
                value: "310.3",
              },
              {
                type: "NRB",
                value: "61.4",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "1.2",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CORONA",
        segment: "P / SP",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "45.8",
              },
              {
                type: "CAN",
                value: "40.6",
              },
              {
                type: "NRB",
                value: "486.7",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "59.9",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "146.9",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "NATIVA",
        segment: "VALUE",
        "Brand Category": "SUSTAIN",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "230.4",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "PILSEN",
        segment: "CORE",
        "Brand Category": "SUSTAIN",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "272.2",
              },
              {
                type: "CAN",
                value: "32.0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "230.4",
              },
              {
                type: "CAN",
                value: "82.6",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "POKER",
        segment: "CORE",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "97.7",
              },
              {
                type: "CAN",
                value: "124.3",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "2591.3",
              },
              {
                type: "CAN",
                value: "556.7",
              },
              {
                type: "NRB",
                value: "4.8",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1603.2",
              },
              {
                type: "CAN",
                value: "220.9",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "STELLA ARTOIS",
        segment: "P / SP",
        "Brand Category": "EXPANSION",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "11.7",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "43.4",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
    ],
    "heineken beverages": [
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "15.1",
              },
              {
                type: "CAN",
                value: "45.9",
              },
              {
                type: "NRB",
                value: "26.8",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "318.7",
              },
              {
                type: "CAN",
                value: "113.4",
              },
              {
                type: "NRB",
                value: "133.4",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "263.1",
              },
              {
                type: "CAN",
                value: "150.5",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "2.4",
              },
              {
                type: "CAN",
                value: "54.8",
              },
              {
                type: "NRB",
                value: "72.9",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0.4",
              },
              {
                type: "CAN",
                value: "55.5",
              },
              {
                type: "NRB",
                value: "15.1",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "9.8",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "ANDINA",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "15.1",
              },
              {
                type: "CAN",
                value: "37.7",
              },
              {
                type: "NRB",
                value: "20.9",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "129.7",
              },
              {
                type: "CAN",
                value: "74.6",
              },
              {
                type: "NRB",
                value: "22.3",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "115.9",
              },
              {
                type: "CAN",
                value: "150.5",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "HEINEKEN",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "2.4",
              },
              {
                type: "CAN",
                value: "54.8",
              },
              {
                type: "NRB",
                value: "72.9",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0.4",
              },
              {
                type: "CAN",
                value: "55.5",
              },
              {
                type: "NRB",
                value: "15.1",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "9.8",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "MILLER",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "7.5",
              },
              {
                type: "NRB",
                value: "6.0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0.1",
              },
              {
                type: "CAN",
                value: "12.8",
              },
              {
                type: "NRB",
                value: "103.9",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "TECATE",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0.7",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "189.0",
              },
              {
                type: "CAN",
                value: "26.0",
              },
              {
                type: "NRB",
                value: "7.1",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "147.2",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
    ],
    "total market": [
      {
        type: "CORE",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "131.4",
              },
              {
                type: "CAN",
                value: "277.3",
              },
              {
                type: "NRB",
                value: "26.8",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "9308.3",
              },
              {
                type: "CAN",
                value: "1555.8",
              },
              {
                type: "NRB",
                value: "154.6",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "3697.7",
              },
              {
                type: "CAN",
                value: "612.6",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "202.9",
              },
              {
                type: "CAN",
                value: "694.7",
              },
              {
                type: "NRB",
                value: "647.8",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "9599.0",
              },
              {
                type: "CAN",
                value: "2021.6",
              },
              {
                type: "NRB",
                value: "360.0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "3697.7",
              },
              {
                type: "CAN",
                value: "622.4",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "P / SP",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "71.5",
              },
              {
                type: "CAN",
                value: "417.4",
              },
              {
                type: "NRB",
                value: "621.0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "60.3",
              },
              {
                type: "CAN",
                value: "56.8",
              },
              {
                type: "NRB",
                value: "205.5",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "9.8",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "VALUE",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "230.4",
              },
              {
                type: "CAN",
                value: "408.9",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    country: "MEX",
    zone: "MAZ",
    abi: [
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "729.4",
              },
              {
                type: "CAN",
                value: "0.0",
              },
              {
                type: "NRB",
                value: "68.1",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "2520.5",
              },
              {
                type: "CAN",
                value: "2169.1",
              },
              {
                type: "NRB",
                value: "64.6",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "8143.3",
              },
              {
                type: "CAN",
                value: "2750.2",
              },
              {
                type: "NRB",
                value: "43.4",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0.0",
              },
              {
                type: "CAN",
                value: "5.4",
              },
              {
                type: "NRB",
                value: "0.0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "271.9",
              },
              {
                type: "CAN",
                value: "1422.8",
              },
              {
                type: "NRB",
                value: "53.4",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "429.5",
              },
              {
                type: "CAN",
                value: "1047.2",
              },
              {
                type: "NRB",
                value: "0.0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0.0",
              },
              {
                type: "CAN",
                value: "0.1",
              },
              {
                type: "NRB",
                value: "6.5",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "124.2",
              },
              {
                type: "CAN",
                value: "124.6",
              },
              {
                type: "NRB",
                value: "116.2",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "24.6",
              },
              {
                type: "CAN",
                value: "58.6",
              },
              {
                type: "NRB",
                value: "0.0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "VALUE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0.0",
              },
              {
                type: "CAN",
                value: "0.0",
              },
              {
                type: "NRB",
                value: "0.0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "5.1",
              },
              {
                type: "CAN",
                value: "145.7",
              },
              {
                type: "NRB",
                value: "932.9",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0.0",
              },
              {
                type: "CAN",
                value: "0.0",
              },
              {
                type: "NRB",
                value: "0.0",
              },
            ],
          },
        ],
      },
      {
        type: "BARRILITO",
        segment: "VALUE",
        "Brand Category": "SUSTAIN",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "5.1",
              },
              {
                type: "CAN",
                value: "145.7",
              },
              {
                type: "NRB",
                value: "932.9",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "BUD LIGHT",
        segment: "CORE",
        "Brand Category": "SUSTAIN",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "449.8",
              },
              {
                type: "NRB",
                value: "16.3",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "76.4",
              },
              {
                type: "CAN",
                value: "79.7",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CORONA",
        segment: "CORE",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "491.2",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "9.2",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "1295.6",
              },
              {
                type: "CAN",
                value: "1307.0",
              },
              {
                type: "NRB",
                value: "27.9",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "4764.2",
              },
              {
                type: "CAN",
                value: "646.3",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "ESTRELLA",
        segment: "CORE",
        "Brand Category": "TAIL",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "5.6",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "9.2",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0.2",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "63.5",
              },
              {
                type: "CAN",
                value: "249.5",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "LEON",
        segment: "CORE",
        "Brand Category": "TAIL",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "5.3",
              },
              {
                type: "CAN",
                value: "0.2",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "46.2",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "MICHELOB",
        segment: "PREMIUM",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "6.5",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "124.2",
              },
              {
                type: "CAN",
                value: "122.7",
              },
              {
                type: "NRB",
                value: "84.5",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "17.9",
              },
              {
                type: "CAN",
                value: "58.6",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "MODELO",
        segment: "CORE+",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "5.4",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "271.9",
              },
              {
                type: "CAN",
                value: "1422.8",
              },
              {
                type: "NRB",
                value: "53.4",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "429.5",
              },
              {
                type: "CAN",
                value: "1047.2",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "PACIFICO",
        segment: "CORE",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "45.2",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "106.2",
              },
              {
                type: "CAN",
                value: "256.1",
              },
              {
                type: "NRB",
                value: "17.1",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "238.8",
              },
              {
                type: "CAN",
                value: "32.1",
              },
              {
                type: "NRB",
                value: "43.4",
              },
            ],
          },
        ],
      },
      {
        type: "STELLA ARTOIS",
        segment: "PREMIUM",
        "Brand Category": "EXPANSION",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0.1",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "1.9",
              },
              {
                type: "NRB",
                value: "31.7",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "6.8",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "VICTORIA",
        segment: "CORE",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "187.4",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "58.8",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "1104.1",
              },
              {
                type: "CAN",
                value: "156.0",
              },
              {
                type: "NRB",
                value: "3.2",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "2954.2",
              },
              {
                type: "CAN",
                value: "1742.5",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
    ],
    "heineken beverages": [
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "639.0",
              },
              {
                type: "CAN",
                value: "0.0",
              },
              {
                type: "NRB",
                value: "0.0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "466.9",
              },
              {
                type: "CAN",
                value: "2252.4",
              },
              {
                type: "NRB",
                value: "95.0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "2684.5",
              },
              {
                type: "CAN",
                value: "1804.9",
              },
              {
                type: "NRB",
                value: "216.0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "174.3",
              },
              {
                type: "CAN",
                value: "0.0",
              },
              {
                type: "NRB",
                value: "0.0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "231.5",
              },
              {
                type: "CAN",
                value: "151.7",
              },
              {
                type: "NRB",
                value: "99.9",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "860.3",
              },
              {
                type: "CAN",
                value: "144.5",
              },
              {
                type: "NRB",
                value: "14.1",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0.0",
              },
              {
                type: "CAN",
                value: "0.1",
              },
              {
                type: "NRB",
                value: "59.6",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "61.2",
              },
              {
                type: "CAN",
                value: "242.3",
              },
              {
                type: "NRB",
                value: "274.9",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "53.2",
              },
              {
                type: "CAN",
                value: "93.9",
              },
              {
                type: "NRB",
                value: "0.2",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "VALUE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0.0",
              },
              {
                type: "CAN",
                value: "0.0",
              },
              {
                type: "NRB",
                value: "0.0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "56.6",
              },
              {
                type: "CAN",
                value: "723.8",
              },
              {
                type: "NRB",
                value: "71.3",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "650.0",
              },
              {
                type: "CAN",
                value: "161.9",
              },
              {
                type: "NRB",
                value: "4.4",
              },
            ],
          },
        ],
      },
      {
        type: "AMSTEL",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "46.5",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "118.8",
              },
              {
                type: "NRB",
                value: "132.1",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "10.8",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "BOHEMIA",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "61.2",
              },
              {
                type: "CAN",
                value: "20.9",
              },
              {
                type: "NRB",
                value: "88.5",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "17.4",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CARTA BLANCA",
        segment: "VALUE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "12.5",
              },
              {
                type: "CAN",
                value: "339.3",
              },
              {
                type: "NRB",
                value: "71.3",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "483.1",
              },
              {
                type: "CAN",
                value: "161.9",
              },
              {
                type: "NRB",
                value: "4.4",
              },
            ],
          },
        ],
      },
      {
        type: "COORS",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "85.9",
              },
              {
                type: "NRB",
                value: "0.5",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "DOS EQUIS",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "174.3",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "231.5",
              },
              {
                type: "CAN",
                value: "151.7",
              },
              {
                type: "NRB",
                value: "99.9",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "860.3",
              },
              {
                type: "CAN",
                value: "144.5",
              },
              {
                type: "NRB",
                value: "14.1",
              },
            ],
          },
        ],
      },
      {
        type: "HEINEKEN",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0.1",
              },
              {
                type: "NRB",
                value: "13.1",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "102.6",
              },
              {
                type: "NRB",
                value: "54.3",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "53.2",
              },
              {
                type: "CAN",
                value: "65.7",
              },
              {
                type: "NRB",
                value: "0.2",
              },
            ],
          },
        ],
      },
      {
        type: "INDIO",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "91.3",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "121.1",
              },
              {
                type: "CAN",
                value: "118.4",
              },
              {
                type: "NRB",
                value: "44.8",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "585.0",
              },
              {
                type: "CAN",
                value: "217.4",
              },
              {
                type: "NRB",
                value: "16.0",
              },
            ],
          },
        ],
      },
      {
        type: "MILLER",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "98.3",
              },
              {
                type: "NRB",
                value: "39.4",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "168.3",
              },
              {
                type: "NRB",
                value: "197.0",
              },
            ],
          },
        ],
      },
      {
        type: "SOL",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "10.4",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "72.6",
              },
              {
                type: "CAN",
                value: "3.3",
              },
              {
                type: "NRB",
                value: "0.1",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "65.3",
              },
              {
                type: "CAN",
                value: "379.1",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "SUPERIOR",
        segment: "VALUE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "44.1",
              },
              {
                type: "CAN",
                value: "384.5",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "166.9",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "TECATE",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "537.3",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "273.2",
              },
              {
                type: "CAN",
                value: "1946.5",
              },
              {
                type: "NRB",
                value: "10.1",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "2034.2",
              },
              {
                type: "CAN",
                value: "1040.0",
              },
              {
                type: "NRB",
                value: "3.0",
              },
            ],
          },
        ],
      },
    ],
    "total market": [
      {
        type: "CORE",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "1368.5",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "68.1",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "2987.4",
              },
              {
                type: "CAN",
                value: "4421.5",
              },
              {
                type: "NRB",
                value: "159.6",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "10827.8",
              },
              {
                type: "CAN",
                value: "4555.0",
              },
              {
                type: "NRB",
                value: "259.4",
              },
            ],
          },
        ],
      },
      {
        type: "CORE+",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "174.3",
              },
              {
                type: "CAN",
                value: "5.4",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "503.4",
              },
              {
                type: "CAN",
                value: "1574.5",
              },
              {
                type: "NRB",
                value: "153.3",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1289.8",
              },
              {
                type: "CAN",
                value: "1191.6",
              },
              {
                type: "NRB",
                value: "14.1",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "1542.8",
              },
              {
                type: "CAN",
                value: "5.6",
              },
              {
                type: "NRB",
                value: "134.1",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "3737.8",
              },
              {
                type: "CAN",
                value: "7232.3",
              },
              {
                type: "NRB",
                value: "1708.2",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "12845.5",
              },
              {
                type: "CAN",
                value: "6061.1",
              },
              {
                type: "NRB",
                value: "278.0",
              },
            ],
          },
        ],
      },
      {
        type: "PREMIUM",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0.2",
              },
              {
                type: "NRB",
                value: "66.0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "185.4",
              },
              {
                type: "CAN",
                value: "367.0",
              },
              {
                type: "NRB",
                value: "391.1",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "77.9",
              },
              {
                type: "CAN",
                value: "152.5",
              },
              {
                type: "NRB",
                value: "0.2",
              },
            ],
          },
        ],
      },
      {
        type: "VALUE",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "61.7",
              },
              {
                type: "CAN",
                value: "869.4",
              },
              {
                type: "NRB",
                value: "1004.2",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "650.0",
              },
              {
                type: "CAN",
                value: "161.9",
              },
              {
                type: "NRB",
                value: "4.4",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    country: "KOR",
    zone: "APAC",
    abi: [
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "20",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "213",
              },
              {
                type: "CAN",
                value: "929",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "87",
              },
              {
                type: "CAN",
                value: "1,158",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "10",
              },
              {
                type: "CAN",
                value: "21",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "297",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "BUDWEISER",
        segment: "PREMIUM",
        "Brand Category": "SUSTAIN",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "9.6",
              },
              {
                type: "CAN",
                value: "12.5",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "153.5",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASS",
        segment: "CORE",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "20.7",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "213.8",
              },
              {
                type: "CAN",
                value: "918.1",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "87.3",
              },
              {
                type: "CAN",
                value: "1128.2",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "HANMAC",
        segment: "CORE",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "11.2",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "30.6",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "HOEGAARDEN",
        segment: "PREMIUM",
        "Brand Category": "SUSTAIN",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0.2",
              },
              {
                type: "CAN",
                value: "5.3",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "59.9",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "STELLA ARTOIS",
        segment: "PREMIUM",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0.3",
              },
              {
                type: "CAN",
                value: "4.1",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "84.6",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
    ],
    bk: [
      {
        type: "Default",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "8",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "6",
              },
              {
                type: "CAN",
                value: "71",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "TSINGTAO",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0.8",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "8.0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "6.6",
              },
              {
                type: "CAN",
                value: "71.9",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
    ],
    diageo: [
      {
        type: "Default",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "54",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "GUINNESS",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "54.4",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
    ],
    "heineken beverages": [
      {
        type: "Default",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "1",
              },
              {
                type: "CAN",
                value: "19",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "114",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "HEINEKEN",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "1.0",
              },
              {
                type: "CAN",
                value: "19.5",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "114.4",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
    ],
    "hite jinro": [
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "10",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "95",
              },
              {
                type: "CAN",
                value: "329",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "575",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "7",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "131",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "HITE",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "13.0",
              },
              {
                type: "CAN",
                value: "64.1",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0.1",
              },
              {
                type: "CAN",
                value: "39.6",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "KELLY",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "34.1",
              },
              {
                type: "CAN",
                value: "91.2",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "172.6",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "KIRIN ICHIBAN",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "3.4",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "60.6",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "KRONENBOURG",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "4.5",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "71.0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "TERRA",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "10.4",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "48.3",
              },
              {
                type: "CAN",
                value: "174.1",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "363.4",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
    ],
    "lotte chilsung": [
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "28",
              },
              {
                type: "CAN",
                value: "51",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "173",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "KLOUD",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "28.3",
              },
              {
                type: "CAN",
                value: "51.4",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "173.3",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
    ],
    "lotteasahijuryu imp": [
      {
        type: "Default",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "145",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "5",
              },
              {
                type: "CAN",
                value: "162",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "ASAHI",
        segment: "PREMIUM",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "145.3",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "5.4",
              },
              {
                type: "CAN",
                value: "162.3",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
    ],
    "total market": [
      {
        type: "CORE",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "31.2",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "337.5",
              },
              {
                type: "CAN",
                value: "1310.1",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "87.3",
              },
              {
                type: "CAN",
                value: "1907.7",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "32.1",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "348.7",
              },
              {
                type: "CAN",
                value: "1512.6",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "99.3",
              },
              {
                type: "CAN",
                value: "2740.3",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "PREMIUM",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0.9",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "11.2",
              },
              {
                type: "CAN",
                value: "202.5",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "11.9",
              },
              {
                type: "CAN",
                value: "832.6",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    country: "SAF",
    zone: "SAF",
    abi: [
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "10",
              },
              {
                type: "NRB",
                value: "367",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "17,725",
              },
              {
                type: "CAN",
                value: "1,371",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "7",
              },
              {
                type: "NRB",
                value: "500",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "3,655",
              },
              {
                type: "CAN",
                value: "1,164",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "1,153",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "130",
              },
              {
                type: "NRB",
                value: "185",
              },
            ],
          },
        ],
      },
      {
        type: "BUDWEISER",
        segment: "P / SP",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "4",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CARLING BLACK LABEL",
        segment: "CORE",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "5",
              },
              {
                type: "NRB",
                value: "221",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "12,893",
              },
              {
                type: "CAN",
                value: "861",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE DOUBLE MALT",
        segment: "CORE+",
        "Brand Category": "Expansion",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "25",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "256",
              },
              {
                type: "CAN",
                value: "79",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE LAGER",
        segment: "CORE",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "4",
              },
              {
                type: "NRB",
                value: "103",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "3,635",
              },
              {
                type: "CAN",
                value: "293",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE LITE",
        segment: "CORE+",
        "Brand Category": "Sustain",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "7",
              },
              {
                type: "NRB",
                value: "435",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1,590",
              },
              {
                type: "CAN",
                value: "897",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE MILK STOUT",
        segment: "CORE+",
        "Brand Category": "Sustain",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "39",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1,809",
              },
              {
                type: "CAN",
                value: "187",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CORONA",
        segment: "P / SP",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "1,002",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "70",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "HANSA PILSNER",
        segment: "CORE",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "42",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "762",
              },
              {
                type: "CAN",
                value: "136",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "LION LAGER",
        segment: "CORE",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "434",
              },
              {
                type: "CAN",
                value: "80",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "STELLA ARTOIS",
        segment: "P / SP",
        "Brand Category": "Expansion",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "146",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "60",
              },
              {
                type: "NRB",
                value: "185",
              },
            ],
          },
        ],
      },
    ],
    "heineken beverages": [
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "13",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "16",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "2",
              },
              {
                type: "NRB",
                value: "303",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1,304",
              },
              {
                type: "CAN",
                value: "415",
              },
              {
                type: "NRB",
                value: "35",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "2",
              },
              {
                type: "NRB",
                value: "471",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1,064",
              },
              {
                type: "CAN",
                value: "381",
              },
              {
                type: "NRB",
                value: "429",
              },
            ],
          },
        ],
      },
      {
        type: "AMSTEL",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "1",
              },
              {
                type: "NRB",
                value: "44",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "788",
              },
              {
                type: "CAN",
                value: "110",
              },
              {
                type: "NRB",
                value: "35",
              },
            ],
          },
        ],
      },
      {
        type: "HEINEKEN",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "2",
              },
              {
                type: "NRB",
                value: "387",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1,064",
              },
              {
                type: "CAN",
                value: "378",
              },
              {
                type: "NRB",
                value: "429",
              },
            ],
          },
        ],
      },
      {
        type: "SOL",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "84",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "2",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "SOWETO GOLD",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "16",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "TAFEL",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "13",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "WINDHOEK",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "259",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "516",
              },
              {
                type: "CAN",
                value: "305",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
    ],
    "total market": [
      {
        type: "CORE",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "10",
              },
              {
                type: "NRB",
                value: "381",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "17,742",
              },
              {
                type: "CAN",
                value: "1,371",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CORE+",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "9",
              },
              {
                type: "NRB",
                value: "803",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "4,960",
              },
              {
                type: "CAN",
                value: "1,580",
              },
              {
                type: "NRB",
                value: "35",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "21",
              },
              {
                type: "NRB",
                value: "2,809",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "23,767",
              },
              {
                type: "CAN",
                value: "3,463",
              },
              {
                type: "NRB",
                value: "650",
              },
            ],
          },
        ],
      },
      {
        type: "P / SP",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "2",
              },
              {
                type: "NRB",
                value: "1,624",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1,064",
              },
              {
                type: "CAN",
                value: "511",
              },
              {
                type: "NRB",
                value: "614",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    country: "BRA",
    zone: "SAZ",
    abi: [
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "3358.2",
              },
              {
                type: "NRB",
                value: "0.2",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "15276.7",
              },
              {
                type: "CAN",
                value: "8569.6",
              },
              {
                type: "NRB",
                value: "137.3",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "4999.2",
              },
              {
                type: "CAN",
                value: "5696.3",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "244.1",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "222.6",
              },
              {
                type: "CAN",
                value: "588.8",
              },
              {
                type: "NRB",
                value: "771.6",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "357.0",
              },
              {
                type: "CAN",
                value: "331.3",
              },
              {
                type: "NRB",
                value: "1.6",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "785.5",
              },
              {
                type: "NRB",
                value: "7.5",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "3319.7",
              },
              {
                type: "CAN",
                value: "1012.5",
              },
              {
                type: "NRB",
                value: "831.6",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "192.4",
              },
              {
                type: "CAN",
                value: "683.2",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "ANTARCTICA",
        segment: "CORE",
        "Brand Category": "SUSTAIN",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "564.4",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "3330.9",
              },
              {
                type: "CAN",
                value: "1598.9",
              },
              {
                type: "NRB",
                value: "0.3",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1329.5",
              },
              {
                type: "CAN",
                value: "1104.4",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "BRAHMA",
        segment: "CORE",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "1057.2",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "6816.2",
              },
              {
                type: "CAN",
                value: "3346.4",
              },
              {
                type: "NRB",
                value: "130.4",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "2191.2",
              },
              {
                type: "CAN",
                value: "3469.8",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "BUDWEISER",
        segment: "CORE+",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "244.1",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "222.6",
              },
              {
                type: "CAN",
                value: "588.8",
              },
              {
                type: "NRB",
                value: "771.6",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "357.0",
              },
              {
                type: "CAN",
                value: "331.3",
              },
              {
                type: "NRB",
                value: "1.6",
              },
            ],
          },
        ],
      },
      {
        type: "ORIGINAL",
        segment: "P / SP",
        "Brand Category": "EXPANSION",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "660.9",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "2009.7",
              },
              {
                type: "CAN",
                value: "546.2",
              },
              {
                type: "NRB",
                value: "131.3",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "192.4",
              },
              {
                type: "CAN",
                value: "377.2",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "SKOL",
        segment: "CORE",
        "Brand Category": "SUSTAIN",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "1736.6",
              },
              {
                type: "NRB",
                value: "0.2",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "5129.6",
              },
              {
                type: "CAN",
                value: "3624.3",
              },
              {
                type: "NRB",
                value: "6.6",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1478.4",
              },
              {
                type: "CAN",
                value: "1122.1",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "SPATEN",
        segment: "P / SP",
        "Brand Category": "MEGA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "90.4",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "819.6",
              },
              {
                type: "CAN",
                value: "399.6",
              },
              {
                type: "NRB",
                value: "295.2",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "182.0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "STELLA ARTOIS",
        segment: "P / SP",
        "Brand Category": "EXPANSION",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "34.3",
              },
              {
                type: "NRB",
                value: "7.5",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "490.4",
              },
              {
                type: "CAN",
                value: "66.6",
              },
              {
                type: "NRB",
                value: "405.1",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "124.0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
    ],
    "cidade imperial petropolis": [
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "265.1",
              },
              {
                type: "NRB",
                value: "138.9",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "20.9",
              },
              {
                type: "CAN",
                value: "618.5",
              },
              {
                type: "NRB",
                value: "511.8",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "582.0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "IMPERIO",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "265.1",
              },
              {
                type: "NRB",
                value: "138.9",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "20.9",
              },
              {
                type: "CAN",
                value: "618.5",
              },
              {
                type: "NRB",
                value: "511.8",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "582.0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
    ],
    "heineken beverages": [
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "922.7",
              },
              {
                type: "NRB",
                value: "33.1",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "619.4",
              },
              {
                type: "CAN",
                value: "2568.4",
              },
              {
                type: "NRB",
                value: "727.0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "257.6",
              },
              {
                type: "CAN",
                value: "713.6",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "583.2",
              },
              {
                type: "NRB",
                value: "229.6",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "2399.7",
              },
              {
                type: "CAN",
                value: "1439.1",
              },
              {
                type: "NRB",
                value: "2754.2",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "1014.4",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "VALUE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "40.7",
              },
              {
                type: "NRB",
                value: "0.6",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "783.1",
              },
              {
                type: "CAN",
                value: "1197.1",
              },
              {
                type: "NRB",
                value: "0.1",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "544.5",
              },
              {
                type: "CAN",
                value: "1349.7",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "AMSTEL",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "881.1",
              },
              {
                type: "NRB",
                value: "33.1",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "248.7",
              },
              {
                type: "CAN",
                value: "1978.9",
              },
              {
                type: "NRB",
                value: "726.9",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "154.6",
              },
              {
                type: "CAN",
                value: "628.8",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "DEVASSA",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "41.6",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "370.8",
              },
              {
                type: "CAN",
                value: "589.5",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "103.0",
              },
              {
                type: "CAN",
                value: "84.8",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "HEINEKEN",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "583.2",
              },
              {
                type: "NRB",
                value: "229.6",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "2399.7",
              },
              {
                type: "CAN",
                value: "1439.1",
              },
              {
                type: "NRB",
                value: "2754.2",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "1014.4",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "KAISER",
        segment: "VALUE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0.6",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "405.2",
              },
              {
                type: "CAN",
                value: "650.2",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "27.1",
              },
              {
                type: "CAN",
                value: "857.9",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "SCHIN",
        segment: "VALUE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "40.7",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "377.9",
              },
              {
                type: "CAN",
                value: "547.0",
              },
              {
                type: "NRB",
                value: "0.1",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "517.4",
              },
              {
                type: "CAN",
                value: "491.9",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
    ],
    petropolis: [
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "227.4",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "365.6",
              },
              {
                type: "CAN",
                value: "621.3",
              },
              {
                type: "NRB",
                value: "38.2",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "44.7",
              },
              {
                type: "CAN",
                value: "458.9",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "VALUE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "789.6",
              },
              {
                type: "NRB",
                value: "60.3",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "1721.9",
              },
              {
                type: "CAN",
                value: "3479.2",
              },
              {
                type: "NRB",
                value: "53.8",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "897.6",
              },
              {
                type: "CAN",
                value: "1732.5",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CRYSTAL",
        segment: "VALUE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "52.6",
              },
              {
                type: "NRB",
                value: "28.8",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "484.7",
              },
              {
                type: "CAN",
                value: "781.8",
              },
              {
                type: "NRB",
                value: "0.1",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "206.5",
              },
              {
                type: "CAN",
                value: "59.2",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "ITAIPAVA",
        segment: "VALUE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "737.0",
              },
              {
                type: "NRB",
                value: "31.5",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "979.6",
              },
              {
                type: "CAN",
                value: "2270.5",
              },
              {
                type: "NRB",
                value: "53.6",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "644.1",
              },
              {
                type: "CAN",
                value: "1379.5",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "LOKAL BIER",
        segment: "VALUE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "257.6",
              },
              {
                type: "CAN",
                value: "427.0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "47.0",
              },
              {
                type: "CAN",
                value: "293.8",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "PETRA",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "227.4",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "365.6",
              },
              {
                type: "CAN",
                value: "621.3",
              },
              {
                type: "NRB",
                value: "38.2",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "44.7",
              },
              {
                type: "CAN",
                value: "458.9",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
    ],
    "total market": [
      {
        type: "CORE",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "4773.4",
              },
              {
                type: "NRB",
                value: "172.2",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "16282.7",
              },
              {
                type: "CAN",
                value: "12377.8",
              },
              {
                type: "NRB",
                value: "1414.2",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "5301.5",
              },
              {
                type: "CAN",
                value: "7450.8",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CORE+",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "244.1",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "222.6",
              },
              {
                type: "CAN",
                value: "588.8",
              },
              {
                type: "NRB",
                value: "771.6",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "357.0",
              },
              {
                type: "CAN",
                value: "331.3",
              },
              {
                type: "NRB",
                value: "1.6",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "7216.5",
              },
              {
                type: "NRB",
                value: "470.1",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "24729.6",
              },
              {
                type: "CAN",
                value: "20094.6",
              },
              {
                type: "NRB",
                value: "5825.6",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "7293.0",
              },
              {
                type: "CAN",
                value: "12562.0",
              },
              {
                type: "NRB",
                value: "1.6",
              },
            ],
          },
        ],
      },
      {
        type: "P / SP",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "1368.8",
              },
              {
                type: "NRB",
                value: "237.1",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "5719.4",
              },
              {
                type: "CAN",
                value: "2451.6",
              },
              {
                type: "NRB",
                value: "3585.8",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "192.4",
              },
              {
                type: "CAN",
                value: "1697.7",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "VALUE",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "830.3",
              },
              {
                type: "NRB",
                value: "60.9",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "2505.0",
              },
              {
                type: "CAN",
                value: "4676.4",
              },
              {
                type: "NRB",
                value: "53.9",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1442.1",
              },
              {
                type: "CAN",
                value: "3082.2",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
    ],
  },
];

export const absPopup = {
  // =============================below is the data for Col=============================
  COL: [
    {
      brand: "AGUILA",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 269,
                  value: "106,155",
                },
                {
                  type: 296,
                  value: "1,008",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 225,
                  value: "341",
                },
                {
                  type: 250,
                  value: "18,210",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 300,
                  value: "191",
                },
                {
                  type: 330,
                  value: "852,322",
                },
                {
                  type: 355,
                  value: "1,213",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "6,126,076",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "16,414",
                },
                {
                  type: 500,
                  value: "1",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "158,567",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 750,
                  value: "418,292",
                },
                {
                  type: 1000,
                  value: "1,182,823",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "ANDINA",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 237,
                  value: "26",
                },
                {
                  type: 269,
                  value: "37,711",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 250,
                  value: "15,120",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 250,
                  value: "20,859",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 310,
                  value: "28,419",
                },
                {
                  type: 330,
                  value: "45,964",
                },
                {
                  type: 355,
                  value: "173",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "129,679",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 300,
                  value: "22,319",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "150,466",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 750,
                  value: "115,909",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "AZTECA",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "126,219",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "2",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "BAHIA",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "282,726",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "BUDWEISER",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 269,
                  value: "310,293",
                },
                {
                  type: 295,
                  value: "2",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 250,
                  value: "23,312",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 250,
                  value: "61,409",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "1,249",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "CORONA",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 269,
                  value: "40,585",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 210,
                  value: "45,803",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 207,
                  value: "1,114",
                },
                {
                  type: 210,
                  value: "485,607",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "59,919",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "106,689",
                },
                {
                  type: 355,
                  value: "40,208",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "HEINEKEN",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 250,
                  value: "3,149",
                },
                {
                  type: 269,
                  value: "51,659",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 250,
                  value: "2,421",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 250,
                  value: "72,864",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 310,
                  value: "26,560",
                },
                {
                  type: 330,
                  value: "28,958",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "397",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "15,140",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "9,825",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 650,
                  value: "8",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "MILLER",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 269,
                  value: "7,471",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 250,
                  value: "5,984",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 310,
                  value: "5,994",
                },
                {
                  type: 330,
                  value: "6,781",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "51",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 300,
                  value: "103,911",
                },
                {
                  type: 355,
                  value: "24",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "NATIVA",
      items: [
        {
          entry: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 250,
                  value: "40",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "230,355",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "PILSEN",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "32,009",
                },
                {
                  type: 355,
                  value: "1",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 300,
                  value: "889",
                },
                {
                  type: 330,
                  value: "271,343",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "82,597",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 750,
                  value: "25",
                },
                {
                  type: 1000,
                  value: "230,327",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "POKER",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 269,
                  value: "123,991",
                },
                {
                  type: 296,
                  value: "274",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 250,
                  value: "97,697",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "556,181",
                },
                {
                  type: 355,
                  value: "567",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 300,
                  value: "644",
                },
                {
                  type: 330,
                  value: "2,590,678",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 300,
                  value: "113",
                },
                {
                  type: 330,
                  value: "4,663",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "220,931",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 750,
                  value: "393,173",
                },
                {
                  type: 1000,
                  value: "1,209,985",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "STELLA ARTOIS",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 269,
                  value: "11,677",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 300,
                  value: "17,186",
                },
                {
                  type: 330,
                  value: "26,228",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "TECATE",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 269,
                  value: "690",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "24,311",
                },
                {
                  type: 355,
                  value: "1,712",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "188,986",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 300,
                  value: "1,581",
                },
                {
                  type: 330,
                  value: "5,564",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 750,
                  value: "147,207",
                },
              ],
            },
          ],
        },
      ],
    },
    // =============================above is the data for Col=============================
    // // ==============below is the data or Mexico==================

    // ==============above is the data or Mexico==================
    // ==============below is the data or SAF==================

    // ==============above is the data or SAF==================
    // ==============below is the data or south korea==================
  ],
  MEX: [
    {
      brand: "AMSTEL",
      items: [
        {
          entry: [
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 190,
                  value: "46,455",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "118,819",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 355,
                  value: "132,087",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "9,405",
                },
                {
                  type: 710,
                  value: "1,369",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 1200,
                  value: "6",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "BARRILITO",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "138",
                },
                {
                  type: 355,
                  value: "145,523",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 325,
                  value: "5,051",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 325,
                  value: "932,671",
                },
                {
                  type: 355,
                  value: "107",
                },
                {
                  type: 444,
                  value: "108",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "BOHEMIA",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "20,873",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 325,
                  value: "32,141",
                },
                {
                  type: 355,
                  value: "29,030",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 355,
                  value: "88,481",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "17,442",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "BUD LIGHT",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "449,840",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 355,
                  value: "16,320",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "11,876",
                },
                {
                  type: 710,
                  value: "67,798",
                },
                {
                  type: 740,
                  value: "2",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 1060,
                  value: "15,389",
                },
                {
                  type: 1200,
                  value: "60,973",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "CARTA BLANCA",
      items: [
        {
          entry: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 190,
                  value: "19",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "11",
                },
                {
                  type: 355,
                  value: "339,277",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 325,
                  value: "12,465",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 300,
                  value: "57,748",
                },
                {
                  type: 325,
                  value: "13,363",
                },
                {
                  type: 355,
                  value: "192",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "161,918",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 940,
                  value: "113,396",
                },
                {
                  type: 1200,
                  value: "369,718",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 940,
                  value: "4,377",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "COORS",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "85,857",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 355,
                  value: "542",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 1200,
                  value: "1",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "CORONA",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 237,
                  value: "10",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 210,
                  value: "491,228",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 210,
                  value: "9,247",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "396,791",
                },
                {
                  type: 355,
                  value: "910,166",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 355,
                  value: "1,295,640",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "19,531",
                },
                {
                  type: 335,
                  value: "1",
                },
                {
                  type: 355,
                  value: "8,322",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 410,
                  value: "5,540",
                },
                {
                  type: 473,
                  value: "538,607",
                },
                {
                  type: 710,
                  value: "102,181",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 710,
                  value: "97",
                },
                {
                  type: 940,
                  value: "2,038,882",
                },
                {
                  type: 946,
                  value: "31",
                },
                {
                  type: 1050,
                  value: "20,563",
                },
                {
                  type: 1200,
                  value: "2,704,616",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 710,
                  value: "2",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "DOS EQUIS",
      items: [
        {
          entry: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 190,
                  value: "174,333",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "151,740",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 325,
                  value: "225,231",
                },
                {
                  type: 355,
                  value: "6,293",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 325,
                  value: "1,756",
                },
                {
                  type: 355,
                  value: "98,102",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "144,462",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 1200,
                  value: "860,281",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 710,
                  value: "12",
                },
                {
                  type: 1200,
                  value: "14,089",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "ESTRELLA",
      items: [
        {
          entry: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 210,
                  value: "5,643",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 355,
                  value: "9,228",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 325,
                  value: "8",
                },
                {
                  type: 355,
                  value: "162",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "246,985",
                },
                {
                  type: 710,
                  value: "2,546",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 1200,
                  value: "63,536",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "HEINEKEN",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 237,
                  value: "59",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 250,
                  value: "13,120",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "102,647",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "26",
                },
                {
                  type: 355,
                  value: "54,321",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "65,683",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 1000,
                  value: "53,230",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 1000,
                  value: "151",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "INDIO",
      items: [
        {
          entry: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 190,
                  value: "91,310",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "118,435",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 325,
                  value: "119,258",
                },
                {
                  type: 355,
                  value: "1,846",
                },
                {
                  type: 473,
                  value: "4",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 355,
                  value: "44,838",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "176,968",
                },
                {
                  type: 710,
                  value: "40,396",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 940,
                  value: "5,067",
                },
                {
                  type: 1200,
                  value: "579,922",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 940,
                  value: "15,990",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "LEON",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "150",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 355,
                  value: "5,317",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 1200,
                  value: "46,178",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "MICHELOB",
      items: [
        {
          entry: [
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 207,
                  value: "113",
                },
                {
                  type: 210,
                  value: "6,341",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "122,687",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 355,
                  value: "124,230",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 355,
                  value: "84,539",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "13,005",
                },
                {
                  type: 710,
                  value: "45,627",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 850,
                  value: "17,865",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "MILLER",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "98,285",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 355,
                  value: "39,416",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "20,939",
                },
                {
                  type: 709,
                  value: "77,796",
                },
                {
                  type: 710,
                  value: "69,565",
                },
                {
                  type: 946,
                  value: "40",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 940,
                  value: "195,856",
                },
                {
                  type: 946,
                  value: "1,163",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "MODELO",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 269,
                  value: "5,351",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "213,932",
                },
                {
                  type: 355,
                  value: "1,208,823",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 355,
                  value: "271,888",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 325,
                  value: "158",
                },
                {
                  type: 330,
                  value: "62",
                },
                {
                  type: 355,
                  value: "53,225",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 410,
                  value: "5,608",
                },
                {
                  type: 473,
                  value: "924,273",
                },
                {
                  type: 710,
                  value: "117,284",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 710,
                  value: "281",
                },
                {
                  type: 1000,
                  value: "429,232",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "PACIFICO",
      items: [
        {
          entry: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 210,
                  value: "45,173",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 210,
                  value: "9",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "256,139",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 355,
                  value: "106,201",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 355,
                  value: "17,114",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 410,
                  value: "32,114",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 940,
                  value: "144,175",
                },
                {
                  type: 1200,
                  value: "94,591",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 1000,
                  value: "43,394",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "SOL",
      items: [
        {
          entry: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 190,
                  value: "10,396",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "3,341",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 325,
                  value: "3,531",
                },
                {
                  type: 355,
                  value: "69,053",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 355,
                  value: "52",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "379,115",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 940,
                  value: "13,872",
                },
                {
                  type: 1200,
                  value: "51,474",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "STELLA ARTOIS",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 269,
                  value: "141",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "1,934",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "31,685",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 900,
                  value: "6,782",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "SUPERIOR",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "384,462",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 325,
                  value: "44,140",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 940,
                  value: "53,835",
                },
                {
                  type: 1200,
                  value: "113,071",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "TECATE",
      items: [
        {
          entry: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 190,
                  value: "537,315",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 350,
                  value: "67",
                },
                {
                  type: 355,
                  value: "1,946,442",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 325,
                  value: "273,095",
                },
                {
                  type: 355,
                  value: "79",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 325,
                  value: "2,760",
                },
                {
                  type: 355,
                  value: "7,363",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "691,607",
                },
                {
                  type: 710,
                  value: "348,435",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 940,
                  value: "261,828",
                },
                {
                  type: 1200,
                  value: "1,771,725",
                },
                {
                  type: 1250,
                  value: "626",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 940,
                  value: "2,980",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "VICTORIA",
      items: [
        {
          entry: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 210,
                  value: "187,391",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 210,
                  value: "58,839",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "77,182",
                },
                {
                  type: 355,
                  value: "78,787",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 355,
                  value: "1,104,105",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 325,
                  value: "738",
                },
                {
                  type: 355,
                  value: "2,449",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 410,
                  value: "6,354",
                },
                {
                  type: 473,
                  value: "1,601,861",
                },
                {
                  type: 710,
                  value: "134,309",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 940,
                  value: "91,540",
                },
                {
                  type: 1200,
                  value: "2,862,695",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  KOR: [
    {
      brand: "ASAHI",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 135,
                  value: "4",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 340,
                  value: "126,876",
                },
                {
                  type: 350,
                  value: "18,472",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 500,
                  value: "162,329",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 640,
                  value: "5,360",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "BUDWEISER",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 270,
                  value: "38",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "5,869",
                },
                {
                  type: 355,
                  value: "6,586",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "9,610",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "14,302",
                },
                {
                  type: 495,
                  value: "3,761",
                },
                {
                  type: 500,
                  value: "94,186",
                },
                {
                  type: 740,
                  value: "41,228",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "CASS",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 250,
                  value: "20,693",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "16,835",
                },
                {
                  type: 350,
                  value: "76",
                },
                {
                  type: 355,
                  value: "548,838",
                },
                {
                  type: 370,
                  value: "331,553",
                },
                {
                  type: 375,
                  value: "20,787",
                },
                {
                  type: 400,
                  value: "33",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "282",
                },
                {
                  type: 500,
                  value: "213,538",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 450,
                  value: "8",
                },
                {
                  type: 464,
                  value: "193,495",
                },
                {
                  type: 473,
                  value: "93,932",
                },
                {
                  type: 475,
                  value: "72",
                },
                {
                  type: 495,
                  value: "30,272",
                },
                {
                  type: 500,
                  value: "735,862",
                },
                {
                  type: 740,
                  value: "74,578",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 640,
                  value: "87,263",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "GUINNESS",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "1",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 440,
                  value: "54,359",
                },
                {
                  type: 500,
                  value: "54",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "HANMAC",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 350,
                  value: "6,059",
                },
                {
                  type: 355,
                  value: "5,125",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 463,
                  value: "6",
                },
                {
                  type: 464,
                  value: "8,711",
                },
                {
                  type: 500,
                  value: "21,843",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "HEINEKEN",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "19,456",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "1,030",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 500,
                  value: "103,998",
                },
                {
                  type: 710,
                  value: "10,450",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 650,
                  value: "4",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "HITE",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 240,
                  value: "49",
                },
                {
                  type: 250,
                  value: "1",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 350,
                  value: "31,425",
                },
                {
                  type: 355,
                  value: "32,634",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "25",
                },
                {
                  type: 500,
                  value: "12,983",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 500,
                  value: "39,618",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 640,
                  value: "66",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "HOEGAARDEN",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "3,097",
                },
                {
                  type: 355,
                  value: "2,159",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "211",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "28",
                },
                {
                  type: 500,
                  value: "59,854",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 750,
                  value: "1",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "KELLY",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 350,
                  value: "109",
                },
                {
                  type: 355,
                  value: "35,906",
                },
                {
                  type: 360,
                  value: "13,930",
                },
                {
                  type: 365,
                  value: "41,283",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "786",
                },
                {
                  type: 500,
                  value: "33,353",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 453,
                  value: "37,842",
                },
                {
                  type: 463,
                  value: "42,719",
                },
                {
                  type: 500,
                  value: "92,047",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "KIRIN ICHIBAN",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 135,
                  value: "2",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 350,
                  value: "3,380",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 500,
                  value: "60,556",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "KLOUD",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "388",
                },
                {
                  type: 350,
                  value: "12,532",
                },
                {
                  type: 355,
                  value: "30,332",
                },
                {
                  type: 360,
                  value: "8,160",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "1",
                },
                {
                  type: 500,
                  value: "28,266",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 470,
                  value: "81,896",
                },
                {
                  type: 500,
                  value: "91,397",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "KRONENBOURG",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "4,493",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 500,
                  value: "70,975",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "STELLA ARTOIS",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 270,
                  value: "35",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "4,124",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "232",
                },
                {
                  type: 500,
                  value: "25",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "8,824",
                },
                {
                  type: 495,
                  value: "2,672",
                },
                {
                  type: 500,
                  value: "50,627",
                },
                {
                  type: 740,
                  value: "22,463",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "TERRA",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 250,
                  value: "10,430",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 345,
                  value: "10,152",
                },
                {
                  type: 350,
                  value: "56",
                },
                {
                  type: 355,
                  value: "118,262",
                },
                {
                  type: 360,
                  value: "13,706",
                },
                {
                  type: 365,
                  value: "31,806",
                },
                {
                  type: 400,
                  value: "70",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "243",
                },
                {
                  type: 355,
                  value: "1,381",
                },
                {
                  type: 500,
                  value: "46,655",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 450,
                  value: "9,413",
                },
                {
                  type: 453,
                  value: "99,955",
                },
                {
                  type: 463,
                  value: "57,523",
                },
                {
                  type: 500,
                  value: "196,532",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 640,
                  value: "20",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "TSINGTAO",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 200,
                  value: "803",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "8,020",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "44",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 500,
                  value: "71,890",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 640,
                  value: "6,570",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  SAF: [
    {
      brand: "AMSTEL",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "1,836",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "44,224",
                },
                {
                  type: 340,
                  value: "31",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 440,
                  value: "107,461",
                },
                {
                  type: 500,
                  value: "2,551",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 660,
                  value: "35,818",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 660,
                  value: "788,399",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "BUDWEISER",
      items: [
        {
          frequency: [
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "4,114",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "CARLING BLACK LABEL",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "5,632",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "221,839",
                },
                {
                  type: 340,
                  value: "1",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 500,
                  value: "861,150",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 750,
                  value: "12,670,057",
                },
                {
                  type: 1000,
                  value: "223,715",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "CASTLE DOUBLE MALT",
      items: [
        {
          frequency: [
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "25,895",
                },
                {
                  type: 340,
                  value: "3",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 410,
                  value: "79,663",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 660,
                  value: "256,052",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "CASTLE LAGER",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "4,870",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "103,800",
                },
                {
                  type: 340,
                  value: "1",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 500,
                  value: "293,457",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 750,
                  value: "3,634,838",
                },
                {
                  type: 1000,
                  value: "523",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "CASTLE LITE",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "7,013",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "435,002",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 410,
                  value: "92,894",
                },
                {
                  type: 500,
                  value: "804,299",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 660,
                  value: "1,590,010",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "CASTLE MILK STOUT",
      items: [
        {
          frequency: [
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "39,302",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 500,
                  value: "187,778",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 750,
                  value: "1,809,716",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "CORONA",
      items: [
        {
          frequency: [
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 355,
                  value: "1,002,454",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 410,
                  value: "70,539",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "HANSA PILSNER",
      items: [
        {
          frequency: [
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "42,203",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 500,
                  value: "136,416",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 750,
                  value: "762,068",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "HEINEKEN",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "2,017",
                },
                {
                  type: 350,
                  value: "146",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "387,007",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 440,
                  value: "334,850",
                },
                {
                  type: 500,
                  value: "43,542",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 650,
                  value: "429,550",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 650,
                  value: "1,064,781",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "LION LAGER",
      items: [
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 500,
                  value: "79,956",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 750,
                  value: "434,520",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "SOL",
      items: [
        {
          frequency: [
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "84,386",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 500,
                  value: "2,594",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 650,
                  value: "131",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "SOWETO GOLD",
      items: [
        {
          intensity: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 660,
                  value: "243",
                },
                {
                  type: 750,
                  value: "16,385",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "STELLA ARTOIS",
      items: [
        {
          frequency: [
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "146,568",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 410,
                  value: "60,193",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 620,
                  value: "185,071",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "TAFEL",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "1",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "13,167",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 500,
                  value: "60",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 660,
                  value: "1",
                },
                {
                  type: 750,
                  value: "317",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "WINDHOEK",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "232",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "1,714",
                },
                {
                  type: 440,
                  value: "257,710",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 440,
                  value: "299,179",
                },
                {
                  type: 500,
                  value: "6,593",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 660,
                  value: "515,458",
                },
                {
                  type: 750,
                  value: "826",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  BRA: [
    {
        "brand": "AMSTEL",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "881,126"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 275,
                                "value": "33,053"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 350,
                                "value": "1,978,878"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "5,033"
                            },
                            {
                                "type": 600,
                                "value": "243,637"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 355,
                                "value": "52,241"
                            },
                            {
                                "type": 600,
                                "value": "674,707"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 473,
                                "value": "628,797"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 1000,
                                "value": "154,578"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "ANTARCTICA",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "564,371"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 350,
                                "value": "1,598,878"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "1,158,115"
                            },
                            {
                                "type": 600,
                                "value": "2,172,760"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "140"
                            },
                            {
                                "type": 355,
                                "value": "146"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 473,
                                "value": "1,096,474"
                            },
                            {
                                "type": 550,
                                "value": "7,933"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 1000,
                                "value": "1,329,531"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "BRAHMA",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "1,057,189"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 310,
                                "value": "6,709"
                            },
                            {
                                "type": 330,
                                "value": "7"
                            },
                            {
                                "type": 350,
                                "value": "3,339,711"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "2,937,281"
                            },
                            {
                                "type": 600,
                                "value": "3,878,963"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "24,691"
                            },
                            {
                                "type": 330,
                                "value": "6,383"
                            },
                            {
                                "type": 355,
                                "value": "72,578"
                            },
                            {
                                "type": 600,
                                "value": "26,721"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 410,
                                "value": "9,383"
                            },
                            {
                                "type": 473,
                                "value": "2,961,805"
                            },
                            {
                                "type": 550,
                                "value": "498,619"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 1000,
                                "value": "2,191,245"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "BUDWEISER",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "244,086"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 310,
                                "value": "4,105"
                            },
                            {
                                "type": 330,
                                "value": "991"
                            },
                            {
                                "type": 350,
                                "value": "583,584"
                            },
                            {
                                "type": 355,
                                "value": "139"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "4,421"
                            },
                            {
                                "type": 600,
                                "value": "218,160"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "205,516"
                            },
                            {
                                "type": 330,
                                "value": "547,232"
                            },
                            {
                                "type": 343,
                                "value": "4,250"
                            },
                            {
                                "type": 355,
                                "value": "143"
                            },
                            {
                                "type": 550,
                                "value": "226"
                            },
                            {
                                "type": 600,
                                "value": "14,274"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 410,
                                "value": "5,353"
                            },
                            {
                                "type": 473,
                                "value": "325,508"
                            },
                            {
                                "type": 5000,
                                "value": "455"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 990,
                                "value": "356,976"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 1000,
                                "value": "1,629"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "CRYSTAL",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "52,619"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 250,
                                "value": "28,792"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 350,
                                "value": "781,770"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "152,284"
                            },
                            {
                                "type": 600,
                                "value": "332,427"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 355,
                                "value": "126"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 473,
                                "value": "59,195"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 1000,
                                "value": "206,487"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "DEVASSA",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "41,574"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 275,
                                "value": "2"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 350,
                                "value": "589,548"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "103,119"
                            },
                            {
                                "type": 600,
                                "value": "267,658"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 355,
                                "value": "2"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 473,
                                "value": "84,817"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 1000,
                                "value": "102,991"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "HEINEKEN",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 250,
                                "value": "501"
                            },
                            {
                                "type": 269,
                                "value": "582,734"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 250,
                                "value": "229,570"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 330,
                                "value": "12,920"
                            },
                            {
                                "type": 350,
                                "value": "1,426,152"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 330,
                                "value": "5,633"
                            },
                            {
                                "type": 600,
                                "value": "2,394,105"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 330,
                                "value": "2,532,562"
                            },
                            {
                                "type": 355,
                                "value": "1,253"
                            },
                            {
                                "type": 600,
                                "value": "220,434"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 473,
                                "value": "964,913"
                            },
                            {
                                "type": 5000,
                                "value": "49,527"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "IMPERIO",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "265,144"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 210,
                                "value": "57,159"
                            },
                            {
                                "type": 275,
                                "value": "81,745"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 350,
                                "value": "618,472"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 600,
                                "value": "20,900"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "155,312"
                            },
                            {
                                "type": 330,
                                "value": "19,658"
                            },
                            {
                                "type": 355,
                                "value": "21,184"
                            },
                            {
                                "type": 600,
                                "value": "315,665"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 473,
                                "value": "582,026"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "ITAIPAVA",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "736,987"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 250,
                                "value": "31,500"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 350,
                                "value": "2,270,486"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "374,879"
                            },
                            {
                                "type": 600,
                                "value": "604,683"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "720"
                            },
                            {
                                "type": 330,
                                "value": "50,825"
                            },
                            {
                                "type": 355,
                                "value": "2,071"
                            },
                            {
                                "type": 600,
                                "value": "26"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 473,
                                "value": "921,136"
                            },
                            {
                                "type": 550,
                                "value": "458,395"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 1000,
                                "value": "644,105"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "KAISER",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "4"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 250,
                                "value": "565"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 350,
                                "value": "650,190"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 600,
                                "value": "405,239"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 473,
                                "value": "853,291"
                            },
                            {
                                "type": 500,
                                "value": "4,592"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 1000,
                                "value": "27,091"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "LOKAL BIER",
        "items": [
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 350,
                                "value": "426,978"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 600,
                                "value": "257,614"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 473,
                                "value": "293,758"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 1000,
                                "value": "47,049"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "ORIGINAL",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "660,878"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 350,
                                "value": "546,220"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "221,424"
                            },
                            {
                                "type": 600,
                                "value": "1,788,323"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "125,699"
                            },
                            {
                                "type": 600,
                                "value": "5,602"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 473,
                                "value": "377,181"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 1000,
                                "value": "192,387"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "PETRA",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "227,355"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 350,
                                "value": "621,294"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "29,462"
                            },
                            {
                                "type": 600,
                                "value": "336,106"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "2,738"
                            },
                            {
                                "type": 330,
                                "value": "34,504"
                            },
                            {
                                "type": 355,
                                "value": "537"
                            },
                            {
                                "type": 500,
                                "value": "383"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 473,
                                "value": "403,815"
                            },
                            {
                                "type": 550,
                                "value": "55,064"
                            },
                            {
                                "type": 5000,
                                "value": "9"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 1000,
                                "value": "44,739"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "SCHIN",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "40,694"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 350,
                                "value": "546,952"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "79"
                            },
                            {
                                "type": 600,
                                "value": "377,785"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 355,
                                "value": "93"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 473,
                                "value": "491,853"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 1000,
                                "value": "517,402"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "SKOL",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "1,736,593"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 275,
                                "value": "238"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 310,
                                "value": "7,183"
                            },
                            {
                                "type": 350,
                                "value": "3,617,125"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "2,367,578"
                            },
                            {
                                "type": 600,
                                "value": "2,762,044"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "6,337"
                            },
                            {
                                "type": 313,
                                "value": "1"
                            },
                            {
                                "type": 330,
                                "value": "84"
                            },
                            {
                                "type": 355,
                                "value": "199"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 410,
                                "value": "3,610"
                            },
                            {
                                "type": 473,
                                "value": "799,904"
                            },
                            {
                                "type": 550,
                                "value": "318,542"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 1000,
                                "value": "1,478,411"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "SPATEN",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "90,390"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 350,
                                "value": "399,642"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 600,
                                "value": "819,568"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 330,
                                "value": "1,383"
                            },
                            {
                                "type": 355,
                                "value": "236,712"
                            },
                            {
                                "type": 600,
                                "value": "57,143"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 473,
                                "value": "182,049"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "STELLA ARTOIS",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "34,258"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 275,
                                "value": "7,523"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 310,
                                "value": "30"
                            },
                            {
                                "type": 330,
                                "value": "1"
                            },
                            {
                                "type": 350,
                                "value": "66,586"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 600,
                                "value": "490,360"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 330,
                                "value": "401,704"
                            },
                            {
                                "type": 550,
                                "value": "2,349"
                            },
                            {
                                "type": 600,
                                "value": "1,008"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 410,
                                "value": "3,275"
                            },
                            {
                                "type": 473,
                                "value": "119,141"
                            },
                            {
                                "type": 600,
                                "value": "1,565"
                            }
                        ]
                    }
                ]
            }
        ]
    }
]
};

export const perPopup = {
  // ========================== below data is for Col ===============================

  COL: [
    {
      brand: "AGUILA",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 269,
                  value: "99.1%",
                },
                {
                  type: 296,
                  value: "0.9%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 225,
                  value: "1.8%",
                },
                {
                  type: 250,
                  value: "98.2%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 300,
                  value: "0%",
                },
                {
                  type: 330,
                  value: "99.8%",
                },
                {
                  type: 355,
                  value: "0.1%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
                {
                  type: 500,
                  value: "0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 750,
                  value: "26.1%",
                },
                {
                  type: 1000,
                  value: "73.9%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "ANDINA",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 237,
                  value: "0%",
                },
                {
                  type: 269,
                  value: "99.9%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 250,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 250,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 310,
                  value: "38.1%",
                },
                {
                  type: 330,
                  value: "61.7%",
                },
                {
                  type: 355,
                  value: "0.2%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 300,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 750,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "AZTECA",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "BAHIA",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "BUDWEISER",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 269,
                  value: "100.0%",
                },
                {
                  type: 295,
                  value: "0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 250,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 250,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "CORONA",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 269,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 210,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 207,
                  value: "0.2%",
                },
                {
                  type: 210,
                  value: "99.8%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "72.6%",
                },
                {
                  type: 355,
                  value: "27.4%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "HEINEKEN",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 250,
                  value: "5.7%",
                },
                {
                  type: 269,
                  value: "94.3%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 250,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 250,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 310,
                  value: "47.8%",
                },
                {
                  type: 330,
                  value: "52.2%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 650,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "MILLER",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 269,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 250,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 310,
                  value: "46.9%",
                },
                {
                  type: 330,
                  value: "53.1%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 300,
                  value: "100.0%",
                },
                {
                  type: 355,
                  value: "0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "NATIVA",
      items: [
        {
          entry: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 250,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "PILSEN",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
                {
                  type: 355,
                  value: "0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 300,
                  value: "0.3%",
                },
                {
                  type: 330,
                  value: "99.7%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 750,
                  value: "0%",
                },
                {
                  type: 1000,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "POKER",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 269,
                  value: "99.8%",
                },
                {
                  type: 296,
                  value: "0.2%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 250,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "99.9%",
                },
                {
                  type: 355,
                  value: "0.1%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 300,
                  value: "0%",
                },
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 300,
                  value: "2.4%",
                },
                {
                  type: 330,
                  value: "97.6%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 750,
                  value: "24.5%",
                },
                {
                  type: 1000,
                  value: "75.5%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "STELLA ARTOIS",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 269,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 300,
                  value: "39.6%",
                },
                {
                  type: 330,
                  value: "60.4%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "TECATE",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 269,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "93.4%",
                },
                {
                  type: 355,
                  value: "6.6%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 300,
                  value: "22.1%",
                },
                {
                  type: 330,
                  value: "77.9%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 750,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
  ],

  // ================================= below data is for Mexico ====================
  MEX: [
    {
      brand: "AMSTEL",
      items: [
        {
          entry: [
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 190,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "87.3%",
                },
                {
                  type: 710,
                  value: "12.7%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 1200,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "BARRILITO",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "0%",
                },
                {
                  type: 355,
                  value: "99.9%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 325,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 325,
                  value: "100.0%",
                },
                {
                  type: 355,
                  value: "0%",
                },
                {
                  type: 444,
                  value: "0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "BOHEMIA",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 325,
                  value: "52.5%",
                },
                {
                  type: 355,
                  value: "47.5%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "BUD LIGHT",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "14.9%",
                },
                {
                  type: 710,
                  value: "85.1%",
                },
                {
                  type: 740,
                  value: "0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 1060,
                  value: "20.2%",
                },
                {
                  type: 1200,
                  value: "79.8%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "CARTA BLANCA",
      items: [
        {
          entry: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 190,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "0%",
                },
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 325,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 300,
                  value: "81.0%",
                },
                {
                  type: 325,
                  value: "18.7%",
                },
                {
                  type: 355,
                  value: "0.3%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 940,
                  value: "23.5%",
                },
                {
                  type: 1200,
                  value: "76.5%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 940,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "COORS",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 1200,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "CORONA",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 237,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 210,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 210,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "30.4%",
                },
                {
                  type: 355,
                  value: "69.6%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "70.1%",
                },
                {
                  type: 335,
                  value: "0%",
                },
                {
                  type: 355,
                  value: "29.9%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 410,
                  value: "0.9%",
                },
                {
                  type: 473,
                  value: "83.3%",
                },
                {
                  type: 710,
                  value: "15.8%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 710,
                  value: "0%",
                },
                {
                  type: 940,
                  value: "42.8%",
                },
                {
                  type: 946,
                  value: "0%",
                },
                {
                  type: 1050,
                  value: "0.4%",
                },
                {
                  type: 1200,
                  value: "56.8%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 710,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "DOS EQUIS",
      items: [
        {
          entry: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 190,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 325,
                  value: "97.3%",
                },
                {
                  type: 355,
                  value: "2.7%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 325,
                  value: "1.8%",
                },
                {
                  type: 355,
                  value: "98.2%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 1200,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 710,
                  value: "0%",
                },
                {
                  type: 1200,
                  value: "99.9%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "ESTRELLA",
      items: [
        {
          entry: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 210,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 325,
                  value: "4.7%",
                },
                {
                  type: 355,
                  value: "95.3%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "99.0%",
                },
                {
                  type: 710,
                  value: "1.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 1200,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "HEINEKEN",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 237,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 250,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "0%",
                },
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 1000,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 1000,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "INDIO",
      items: [
        {
          entry: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 190,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 325,
                  value: "98.5%",
                },
                {
                  type: 355,
                  value: "1.5%",
                },
                {
                  type: 473,
                  value: "0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "81.4%",
                },
                {
                  type: 710,
                  value: "18.6%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 940,
                  value: "0.9%",
                },
                {
                  type: 1200,
                  value: "99.1%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 940,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "LEON",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 1200,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "MICHELOB",
      items: [
        {
          entry: [
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 207,
                  value: "1.8%",
                },
                {
                  type: 210,
                  value: "98.2%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "22.2%",
                },
                {
                  type: 710,
                  value: "77.8%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 850,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "MILLER",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "12.4%",
                },
                {
                  type: 709,
                  value: "46.2%",
                },
                {
                  type: 710,
                  value: "41.3%",
                },
                {
                  type: 946,
                  value: "0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 940,
                  value: "99.4%",
                },
                {
                  type: 946,
                  value: "0.6%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "MODELO",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 269,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "15.0%",
                },
                {
                  type: 355,
                  value: "85.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 325,
                  value: "0.3%",
                },
                {
                  type: 330,
                  value: "0.1%",
                },
                {
                  type: 355,
                  value: "99.6%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 410,
                  value: "0.5%",
                },
                {
                  type: 473,
                  value: "88.3%",
                },
                {
                  type: 710,
                  value: "11.2%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 710,
                  value: "0%",
                },
                {
                  type: 1000,
                  value: "99.9%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "PACIFICO",
      items: [
        {
          entry: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 210,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 210,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 410,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 940,
                  value: "60.4%",
                },
                {
                  type: 1200,
                  value: "39.6%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 1000,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "SOL",
      items: [
        {
          entry: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 190,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 325,
                  value: "4.9%",
                },
                {
                  type: 355,
                  value: "95.1%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 940,
                  value: "21.2%",
                },
                {
                  type: 1200,
                  value: "78.8%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "STELLA ARTOIS",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 269,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 900,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "SUPERIOR",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 325,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 940,
                  value: "32.3%",
                },
                {
                  type: 1200,
                  value: "67.7%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "TECATE",
      items: [
        {
          entry: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 190,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 350,
                  value: "0%",
                },
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 325,
                  value: "100.0%",
                },
                {
                  type: 355,
                  value: "0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 325,
                  value: "27.3%",
                },
                {
                  type: 355,
                  value: "72.7%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "66.5%",
                },
                {
                  type: 710,
                  value: "33.5%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 940,
                  value: "12.9%",
                },
                {
                  type: 1200,
                  value: "87.1%",
                },
                {
                  type: 1250,
                  value: "0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 940,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "VICTORIA",
      items: [
        {
          entry: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 210,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 210,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "49.5%",
                },
                {
                  type: 355,
                  value: "50.5%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 325,
                  value: "23.2%",
                },
                {
                  type: 355,
                  value: "76.8%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 410,
                  value: "0.4%",
                },
                {
                  type: 473,
                  value: "91.9%",
                },
                {
                  type: 710,
                  value: "7.7%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 940,
                  value: "3.1%",
                },
                {
                  type: 1200,
                  value: "96.9%",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  // ===========================below data is for south africa=======================
  SAF: [
    {
      brand: "AMSTEL",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "99.9%",
                },
                {
                  type: 340,
                  value: "0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 440,
                  value: "97.7%",
                },
                {
                  type: 500,
                  value: "2.3%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 660,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 660,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "BUDWEISER",
      items: [
        {
          frequency: [
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "CARLING BLACK LABEL",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
                {
                  type: 340,
                  value: "0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 500,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 750,
                  value: "98.3%",
                },
                {
                  type: 1000,
                  value: "1.7%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "CASTLE DOUBLE MALT",
      items: [
        {
          frequency: [
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
                {
                  type: 340,
                  value: "0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 410,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 660,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "CASTLE LAGER",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
                {
                  type: 340,
                  value: "0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 500,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 750,
                  value: "100.0%",
                },
                {
                  type: 1000,
                  value: "0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "CASTLE LITE",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 410,
                  value: "10.4%",
                },
                {
                  type: 500,
                  value: "89.6%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 660,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "CASTLE MILK STOUT",
      items: [
        {
          frequency: [
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 500,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 750,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "CORONA",
      items: [
        {
          frequency: [
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 355,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 410,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "HANSA PILSNER",
      items: [
        {
          frequency: [
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 500,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 750,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "HEINEKEN",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "93.3%",
                },
                {
                  type: 350,
                  value: "6.7%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 440,
                  value: "88.5%",
                },
                {
                  type: 500,
                  value: "11.5%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 650,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 650,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "LION LAGER",
      items: [
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 500,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 750,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "SOL",
      items: [
        {
          frequency: [
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 500,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 650,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "SOWETO GOLD",
      items: [
        {
          intensity: [
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 660,
                  value: "1.5%",
                },
                {
                  type: 750,
                  value: "98.5%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "STELLA ARTOIS",
      items: [
        {
          frequency: [
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 410,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 620,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "TAFEL",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 500,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 660,
                  value: "0.3%",
                },
                {
                  type: 750,
                  value: "99.7%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "WINDHOEK",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "NRB",
              pack_sizes: [
                {
                  type: 330,
                  value: "0.7%",
                },
                {
                  type: 440,
                  value: "99.3%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 440,
                  value: "97.8%",
                },
                {
                  type: 500,
                  value: "2.2%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 660,
                  value: "99.8%",
                },
                {
                  type: 750,
                  value: "0.2%",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  // ===========================below data is for south korea=======================
  KOR: [
    {
      brand: "ASAHI",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 135,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 340,
                  value: "87.3%",
                },
                {
                  type: 350,
                  value: "12.7%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 500,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 640,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "BUDWEISER",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 270,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "47.1%",
                },
                {
                  type: 355,
                  value: "52.9%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "9.3%",
                },
                {
                  type: 495,
                  value: "2.5%",
                },
                {
                  type: 500,
                  value: "61.4%",
                },
                {
                  type: 740,
                  value: "26.9%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "CASS",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 250,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "1.8%",
                },
                {
                  type: 350,
                  value: "0%",
                },
                {
                  type: 355,
                  value: "59.8%",
                },
                {
                  type: 370,
                  value: "36.1%",
                },
                {
                  type: 375,
                  value: "2.3%",
                },
                {
                  type: 400,
                  value: "0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "0.1%",
                },
                {
                  type: 500,
                  value: "99.9%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 450,
                  value: "0%",
                },
                {
                  type: 464,
                  value: "17.2%",
                },
                {
                  type: 473,
                  value: "8.3%",
                },
                {
                  type: 475,
                  value: "0%",
                },
                {
                  type: 495,
                  value: "2.7%",
                },
                {
                  type: 500,
                  value: "65.2%",
                },
                {
                  type: 740,
                  value: "6.6%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 640,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "GUINNESS",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 440,
                  value: "99.9%",
                },
                {
                  type: 500,
                  value: "0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "HANMAC",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 350,
                  value: "54.2%",
                },
                {
                  type: 355,
                  value: "45.8%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 463,
                  value: "0%",
                },
                {
                  type: 464,
                  value: "28.5%",
                },
                {
                  type: 500,
                  value: "71.5%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "HEINEKEN",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 500,
                  value: "90.9%",
                },
                {
                  type: 710,
                  value: "9.1%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 650,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "HITE",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 240,
                  value: "98.0%",
                },
                {
                  type: 250,
                  value: "2.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 350,
                  value: "49.1%",
                },
                {
                  type: 355,
                  value: "50.9%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "0.2%",
                },
                {
                  type: 500,
                  value: "99.8%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 500,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 640,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "HOEGAARDEN",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "58.9%",
                },
                {
                  type: 355,
                  value: "41.1%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "0%",
                },
                {
                  type: 500,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 750,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "KELLY",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 350,
                  value: "0.1%",
                },
                {
                  type: 355,
                  value: "39.4%",
                },
                {
                  type: 360,
                  value: "15.3%",
                },
                {
                  type: 365,
                  value: "45.3%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "2.3%",
                },
                {
                  type: 500,
                  value: "97.7%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 453,
                  value: "21.9%",
                },
                {
                  type: 463,
                  value: "24.7%",
                },
                {
                  type: 500,
                  value: "53.3%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "KIRIN ICHIBAN",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 135,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 350,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 500,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "KLOUD",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "0.8%",
                },
                {
                  type: 350,
                  value: "24.4%",
                },
                {
                  type: 355,
                  value: "59.0%",
                },
                {
                  type: 360,
                  value: "15.9%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "0%",
                },
                {
                  type: 500,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 470,
                  value: "47.3%",
                },
                {
                  type: 500,
                  value: "52.7%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "KRONENBOURG",
      items: [
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 500,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "STELLA ARTOIS",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 270,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "90.3%",
                },
                {
                  type: 500,
                  value: "9.7%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 473,
                  value: "10.4%",
                },
                {
                  type: 495,
                  value: "3.2%",
                },
                {
                  type: 500,
                  value: "59.9%",
                },
                {
                  type: 740,
                  value: "26.6%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "TERRA",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 250,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 345,
                  value: "5.8%",
                },
                {
                  type: 350,
                  value: "0%",
                },
                {
                  type: 355,
                  value: "67.9%",
                },
                {
                  type: 360,
                  value: "7.9%",
                },
                {
                  type: 365,
                  value: "18.3%",
                },
                {
                  type: 400,
                  value: "0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "0.5%",
                },
                {
                  type: 355,
                  value: "2.9%",
                },
                {
                  type: 500,
                  value: "96.6%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 450,
                  value: "2.6%",
                },
                {
                  type: 453,
                  value: "27.5%",
                },
                {
                  type: 463,
                  value: "15.8%",
                },
                {
                  type: 500,
                  value: "54.1%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 640,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      brand: "TSINGTAO",
      items: [
        {
          entry: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 200,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          frequency: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 330,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
        {
          intensity: [
            {
              type: "CAN",
              pack_sizes: [
                {
                  type: 500,
                  value: "100.0%",
                },
              ],
            },
            {
              type: "RGB",
              pack_sizes: [
                {
                  type: 640,
                  value: "100.0%",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  BRA:  [
    {
        "brand": "AMSTEL",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 275,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 350,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "2.0%"
                            },
                            {
                                "type": 600,
                                "value": "98.0%"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 355,
                                "value": "7.2%"
                            },
                            {
                                "type": 600,
                                "value": "92.8%"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 473,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 1000,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "ANTARCTICA",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 350,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "34.8%"
                            },
                            {
                                "type": 600,
                                "value": "65.2%"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "49.0%"
                            },
                            {
                                "type": 355,
                                "value": "51.0%"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 473,
                                "value": "99.3%"
                            },
                            {
                                "type": 550,
                                "value": "0.7%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 1000,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "BRAHMA",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 310,
                                "value": "0.2%"
                            },
                            {
                                "type": 330,
                                "value": "0%"
                            },
                            {
                                "type": 350,
                                "value": "99.8%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "43.1%"
                            },
                            {
                                "type": 600,
                                "value": "56.9%"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "18.9%"
                            },
                            {
                                "type": 330,
                                "value": "4.9%"
                            },
                            {
                                "type": 355,
                                "value": "55.7%"
                            },
                            {
                                "type": 600,
                                "value": "20.5%"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 410,
                                "value": "0.3%"
                            },
                            {
                                "type": 473,
                                "value": "85.4%"
                            },
                            {
                                "type": 550,
                                "value": "14.4%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 1000,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "BUDWEISER",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 310,
                                "value": "0.7%"
                            },
                            {
                                "type": 330,
                                "value": "0.2%"
                            },
                            {
                                "type": 350,
                                "value": "99.1%"
                            },
                            {
                                "type": 355,
                                "value": "0%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "2.0%"
                            },
                            {
                                "type": 600,
                                "value": "98.0%"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "26.6%"
                            },
                            {
                                "type": 330,
                                "value": "70.9%"
                            },
                            {
                                "type": 343,
                                "value": "0.6%"
                            },
                            {
                                "type": 355,
                                "value": "0%"
                            },
                            {
                                "type": 550,
                                "value": "0%"
                            },
                            {
                                "type": 600,
                                "value": "1.8%"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 410,
                                "value": "1.6%"
                            },
                            {
                                "type": 473,
                                "value": "98.2%"
                            },
                            {
                                "type": 5000,
                                "value": "0.1%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 990,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 1000,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "CRYSTAL",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 250,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 350,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "31.4%"
                            },
                            {
                                "type": 600,
                                "value": "68.6%"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 355,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 473,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 1000,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "DEVASSA",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 275,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 350,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "27.8%"
                            },
                            {
                                "type": 600,
                                "value": "72.2%"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 355,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 473,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 1000,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "HEINEKEN",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 250,
                                "value": "0%"
                            },
                            {
                                "type": 269,
                                "value": "99.9%"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 250,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 330,
                                "value": "0.9%"
                            },
                            {
                                "type": 350,
                                "value": "99.1%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 330,
                                "value": "0.2%"
                            },
                            {
                                "type": 600,
                                "value": "99.8%"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 330,
                                "value": "92.0%"
                            },
                            {
                                "type": 355,
                                "value": "0%"
                            },
                            {
                                "type": 600,
                                "value": "8.0%"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 473,
                                "value": "95.1%"
                            },
                            {
                                "type": 5000,
                                "value": "4.9%"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "IMPERIO",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 210,
                                "value": "41.1%"
                            },
                            {
                                "type": 275,
                                "value": "58.9%"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 350,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 600,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "30.3%"
                            },
                            {
                                "type": 330,
                                "value": "3.8%"
                            },
                            {
                                "type": 355,
                                "value": "4.1%"
                            },
                            {
                                "type": 600,
                                "value": "61.7%"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 473,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "ITAIPAVA",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 250,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 350,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "38.3%"
                            },
                            {
                                "type": 600,
                                "value": "61.7%"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "1.3%"
                            },
                            {
                                "type": 330,
                                "value": "94.7%"
                            },
                            {
                                "type": 355,
                                "value": "3.9%"
                            },
                            {
                                "type": 600,
                                "value": "0%"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 473,
                                "value": "66.8%"
                            },
                            {
                                "type": 550,
                                "value": "33.2%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 1000,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "KAISER",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 250,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 350,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 600,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 473,
                                "value": "99.5%"
                            },
                            {
                                "type": 500,
                                "value": "0.5%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 1000,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "LOKAL BIER",
        "items": [
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 350,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 600,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 473,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 1000,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "ORIGINAL",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 350,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "11.0%"
                            },
                            {
                                "type": 600,
                                "value": "89.0%"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "95.7%"
                            },
                            {
                                "type": 600,
                                "value": "4.3%"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 473,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 1000,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "PETRA",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 350,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "8.1%"
                            },
                            {
                                "type": 600,
                                "value": "91.9%"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "7.2%"
                            },
                            {
                                "type": 330,
                                "value": "90.4%"
                            },
                            {
                                "type": 355,
                                "value": "1.4%"
                            },
                            {
                                "type": 500,
                                "value": "1.0%"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 473,
                                "value": "88.0%"
                            },
                            {
                                "type": 550,
                                "value": "12.0%"
                            },
                            {
                                "type": 5000,
                                "value": "0%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 1000,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "SCHIN",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 350,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "0%"
                            },
                            {
                                "type": 600,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 355,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 473,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 1000,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "SKOL",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 275,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 310,
                                "value": "0.2%"
                            },
                            {
                                "type": 350,
                                "value": "99.8%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "46.2%"
                            },
                            {
                                "type": 600,
                                "value": "53.8%"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 300,
                                "value": "95.7%"
                            },
                            {
                                "type": 313,
                                "value": "0%"
                            },
                            {
                                "type": 330,
                                "value": "1.3%"
                            },
                            {
                                "type": 355,
                                "value": "3.0%"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 410,
                                "value": "0.3%"
                            },
                            {
                                "type": 473,
                                "value": "71.3%"
                            },
                            {
                                "type": 550,
                                "value": "28.4%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 1000,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "SPATEN",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 350,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 600,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 330,
                                "value": "0.5%"
                            },
                            {
                                "type": 355,
                                "value": "80.2%"
                            },
                            {
                                "type": 600,
                                "value": "19.4%"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 473,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "brand": "STELLA ARTOIS",
        "items": [
            {
                "entry": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 269,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 275,
                                "value": "100.0%"
                            }
                        ]
                    }
                ]
            },
            {
                "frequency": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 310,
                                "value": "0%"
                            },
                            {
                                "type": 330,
                                "value": "0%"
                            },
                            {
                                "type": 350,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "RGB",
                        "pack_sizes": [
                            {
                                "type": 600,
                                "value": "100.0%"
                            }
                        ]
                    },
                    {
                        "type": "NRB",
                        "pack_sizes": [
                            {
                                "type": 330,
                                "value": "99.2%"
                            },
                            {
                                "type": 550,
                                "value": "0.6%"
                            },
                            {
                                "type": 600,
                                "value": "0.2%"
                            }
                        ]
                    }
                ]
            },
            {
                "intensity": [
                    {
                        "type": "CAN",
                        "pack_sizes": [
                            {
                                "type": 410,
                                "value": "2.6%"
                            },
                            {
                                "type": 473,
                                "value": "96.1%"
                            },
                            {
                                "type": 600,
                                "value": "1.3%"
                            }
                        ]
                    }
                ]
            }
        ]
    }
]
};

export const pptData = [
  {
    Zone: "Africa",
    Country: "South Africa",
    Financials_source: "UMAMI",
    Refresh_year: 2024,
    Refresh_month: "August",
  },
  {
    Zone: "SAZ",
    Country: "Brazil",
    Financials_source: "CUBO",
    Refresh_year: 2024,
    Refresh_month: "August",
  },
];

export const filterByCountry = (country: string) =>
  pptData.filter((item) => item.Country === country)[0];

export const chakraStyles = (
  customWidth: string,
  customFont: string,
  customAlign: string
) => ({
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    width: customWidth,
    fontSize: customFont,
    fontWeight: "600",
    textAlign: customAlign,
    color: "black",
    bg: "white",
    padding: "0 16px 0",
    backgroundColor: "white !important",
    // border: "none",
    borderColor: state.isFocused
      ? "primaryBlack !important"
      : "border !important",
    borderRadius: "8px",
    boxShadow: "none !important",
  }),
  placeholder: (baseStyles: any) => ({
    ...baseStyles,
    color: "#1E1E1ECC",
  }),
  valueContainer: (baseStyles: any) => ({
    ...baseStyles,
    px: "0",
  }),
  multiValue: (baseStyles: any) => ({
    ...baseStyles,
    bg: "border",
    fontSize: "12px",
  }),
  dropdownIndicator: (baseStyles: any) => ({
    ...baseStyles,
    px: "200px",
    bg: "green",
    cursor: "pointer",
  }),
  option: (baseStyles: any, state: any) => ({
    ...baseStyles,
    color: "textBlack !important",
    fontSize: "12px",
    background: state.isFocused ? "border !important" : "none",
  }),
});
export const filterInfo: { [key: string]: string } = {
  "Price per HL": "Expected NR per HL",
  "MACO per HL": "Unplugged MACO/HL",
  Distribution: "% of POC where the product will be available",
};

export const desiredOrder = [
  "P_Brand",
  "P_Subbrand",
  "P_Flavour",
  "P_Category",
  "P_SubCategory",
  "P_Beer_Type",
  "P_Pack_Size",
  "P_Price_Segment",
  "P_ABV",
  "P_Pack_Return_Type",
  "P_MACO_per_HL",
  "P_Launch_Period",
];

export const getColumns = (
  isShared: boolean,
  editName: CellComponent,
  preCheckBox: CellComponent,
  actionbuttons: CellComponent
): ColumnDef<ScenarioType>[] => {
  const columns: ColumnDef<ScenarioType>[] = [
    {
      header: CheckboxHeader,
      accessorKey: "checkbox",
      cell: preCheckBox,
    },
    {
      header: "Scenario Name",
      accessorKey: "name",
      cell: editName,
    },
    {
      header: "Created On",
      accessorKey: "date",
      cell: formatDate,
    },

    {
      header: "Country",
      accessorKey: "Country",
      cell: TableCell,
    },

    ...(isShared
      ? [
          {
            header: "Created By",
            accessorKey: "created_by",
            cell: TableCell,
          },
        ]
      : []),
    {
      header: "Brand",
      accessorKey: "brand",
      cell: TableCell,
    },
    {
      header: "Total Volume",
      accessorKey: "incremental_nr",
      cell: TableCell,
    },
    {
      header: "Incremental Volume",
      accessorKey: "incremental_volume_%",
      cell: TableCell,
    },
    {
      header: IncMaCoHeader,
      accessorKey: "incremental_maco",
      cell: TableCell,
    },
    {
      header: "Actions",
      accessorKey: "actions",
      cell: actionbuttons,
    },
  ];
  return columns;
};
export const RegionalColumn = [
  {
    header: "Districts",
    accessorKey: "R_Districts",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "POC Segments",
    accessorKey: "R_POC_Segments",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "NR per HL (USD)",
    accessorKey: "R_Price_per_HL",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "Distribution %",
    accessorKey: "R_Distribution",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
];

export const BrazilRegionalColumn = [
  {
    header: "Regions",
    accessorKey: "R_Regions",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "Channels",
    accessorKey: "R_Channels",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "NR per HL (USD)",
    accessorKey: "R_Price_per_HL",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "Distribution %",
    accessorKey: "R_Distribution",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
];
export const InnoColumns = (isSwitchOn: boolean) => [
  {
    header: "Brand",
    accessorKey: "brand",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal" fontWeight={500} fontFamily="Avantt bold">
        {props.getValue()}
      </Text>
    ),
  },
  {
    header: "Pack Type",
    accessorKey: "packType",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal" fontFamily="Avantt bold">
        {props.getValue()}
      </Text>
    ),
  },
  {
    header: "Pack Size",
    accessorKey: "packSize",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal" fontFamily="Avantt bold">
        {props.getValue()}
      </Text>
    ),
  },
  {
    header: isSwitchOn ? "Volume" : "Volume %",
    accessorKey: "volume",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal" fontFamily="Avantt bold">
        {props.getValue()}
      </Text>
    ),
  },
];

export const createColumns = (
  lastColumn: (props: { row: Row<UserData> }) => JSX.Element
) => [
  {
    header: "Email",
    accessorKey: "email",
    cell: (props: { getValue: () => string }) => (
      <p style={{ color: "#886612" }}> {props.getValue()}</p>
    ),
  },
  {
    header: "Name",
    accessorKey: "username",
    cell: (props: { getValue: () => string }) => <p> {props.getValue()}</p>,
  },
  {
    header: "Zone",
    accessorKey: "approval_for",
    cell: (props: { getValue: () => string }) => <p> {props.getValue()}</p>,
  },
  {
    header: "Role",
    accessorKey: "role",
    cell: (props: { getValue: () => string }) => <p> {props.getValue()}</p>,
  },
  {
    header: "Manage Access",
    accessorKey: "manage_access",
    cell: lastColumn,
  },
];
