import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Colombia from "innovate/assets/flags/Colombia.svg.png";
import NigeriaFlag from "innovate/assets/flags/NigeriaFlag.svg";
import SAFLAG from "innovate/assets/flags/RSAFLAG.svg";
import Loader from "innovate/components/common/Loader";
import DynamicTable from "innovate/components/DynamicTable/DynamicTable";
import InnoExploreGrid, {
  Entry,
} from "innovate/pages/InnoExplore/InnoExploreTable";
import { absPopup, InnoColumns, perPopup } from "innovate/utils/Constants";
import {
  createBrandLabel,
  findMatchingItems,
  getBrandLabelColor,
  getLabelContent,
  LabelsGrid,
  sortByBrandCategory,
} from "innovate/utils/ExploreHelper/ExploreHelper";
import {
  filterDefaultValues,
  getBackgroundColorCat,
  moveAbiToLastPosition,
} from "innovate/utils/Helper";
import { Category, CountryData, UpdatePopupData } from "innovate/utils/types";
import React, { useEffect, useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { v4 as uuidv4 } from "uuid";

interface ExploreCountryProps {
  data: CountryData;
  isSwitchOn: boolean;
}

type ModalType = {
  brand: string;
  packType: string;
  packSize: string;
  volume: string;
};

interface PackSize {
  type: number | string;
  value: string;
}

interface EntryItem {
  type: string;
  pack_sizes: PackSize[];
}

interface FrequencyItem {
  type: string;
  pack_sizes: PackSize[];
}

interface IntensityItem {
  type: string;
  pack_sizes: PackSize[];
}

interface PopupItem {
  entry?: EntryItem[];
  frequency?: FrequencyItem[];
  intensity?: IntensityItem[];
}

interface CountryPopData {
  brand: string;
  items: PopupItem[];
}

interface PopupData {
  [country: string]: CountryPopData[];
}
type HandleClickType = (
  category: Category,
  entry: Entry,
  dynamicKey: string,
  country: string
) => void;
type HandleOpenCat = (category: Category, id: string) => void;

const renderCategory = (
  category: Category,
  index: number,
  handleOpenCat: HandleOpenCat,
  expandedKeys: Set<string>
) => (
  <Flex
    key={`total_market-${index}`}
    mb={2}
    h="34px"
    id={`total_market-${index}`}
    padding="8px"
    width="100%"
    borderRadius="md"
    fontWeight="600"
    justifyContent="space-between"
    alignItems="center"
    cursor="pointer"
    height="35px"
    bg={getBackgroundColorCat(category)}
    onClick={() => handleOpenCat(category, `total_market-${index}`)}
  >
    <Flex dir="row">
      {category["Brand Category"] !== "" && (
        <Text
          variant="custom"
          bg={getBrandLabelColor(category["Brand Category"] ?? "")}
          p={1}
          mr={1}
          borderRadius="50%"
          width="16px"
          height="16px"
          display="flex"
          fontSize="10px"
          alignItems="center"
          justifyContent="center"
        >
          {category["Brand Category"]?.charAt(0)}
        </Text>
      )}
      <Text variant="custom" ml={1} isTruncated>
        {createBrandLabel(category)}
      </Text>
    </Flex>
    {(category.type === "Default" || category.segment === "") &&
      (expandedKeys.has(`total_market-${index}`) ? (
        <FiChevronUp />
      ) : (
        <FiChevronDown />
      ))}
  </Flex>
);

const renderLabelsGrid = (label: string) => (
  <Box key={label} flex="1" maxWidth="33%" p={0} minWidth="200px">
    <Center
      mb={2}
      border="1px solid #D8D8D8"
      p={1}
      borderRadius="md"
      bg="#F2F2F2"
    >
      <Text variant="custom">{label}</Text>
      <Box ml={2} px={1} bg="yellow.400" borderRadius="md">
        <Text variant="custom" className="explorePack" fontSize="10px">
          {getLabelContent(label)}
        </Text>
      </Box>
    </Center>
    <LabelsGrid label={label} />
  </Box>
);

const renderCategoryGrid = (
  category: Category,
  filteredData: CountryData,
  handleClick: HandleClickType
) =>
  Object.entries(category).map(([categoryKey, categoryValue]) => {
    if (!Array.isArray(categoryValue)) return null;
    console.log("amolfil", category);

    return (
      <Box key={categoryKey} mb={2}>
        <Grid templateColumns="repeat(auto-fit, minmax(200px, 1fr))" gap={6}>
          {categoryValue.map((entry) => (
            <GridItem key={`${category.type}-${uuidv4()}`} p={0}>
              <InnoExploreGrid
                data={entry}
                category={category}
                country={filteredData.country}
                color={getBackgroundColorCat(category)}
                handleClick={handleClick}
              />
            </GridItem>
          ))}
        </Grid>
      </Box>
    );
  });

const ExploreCountry: React.FC<ExploreCountryProps> = ({
  data,
  isSwitchOn,
}) => {
  const [filteredData, setFilteredData] = useState<CountryData | null>(null);
  const [selectedCategory, setSelectedCategory] =
    useState<UpdatePopupData | null>(null);
  const [modalData, setModalData] = useState<ModalType[]>([]);

  const [expandedKeys, setExpandedKeys] = useState<Set<string>>(new Set());
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setExpandedKeys(new Set());
  }, [isSwitchOn]);

  useEffect(() => {
    const defaultData = filterDefaultValues(data);
    setFilteredData(defaultData);
  }, [data]);

  const columns = InnoColumns(isSwitchOn);

  const countryFlags: { [key: string]: string } = {
    SAF: SAFLAG,
    Nigeria: NigeriaFlag,
    COL: Colombia,
    // Add more countries and their flags here
  };
  const removeEntriesWithSameSegment = (
    key: string,
    masterKey: string,
    isSubCategory: boolean,
    prevData: CountryData
  ) => {
    const updatedData = { ...prevData };
    updatedData["total market"] = prevData["total market"].filter(
      (cat) =>
        cat.segment !== key ||
        (isSubCategory && cat.type === "Default" && cat.segment === key) ||
        (isSubCategory && cat.dataKey !== masterKey)
    );
    return updatedData;
  };

  const updateFilteredDataWithSubCategory = (
    key: string,
    masterKey: string,
    matchingItems: CountryData["total market"],
    prevData: CountryData
  ) => {
    const updatedData = { ...prevData };
    const totalMarket = updatedData["total market"] || [];

    // Ensure uniqueness in matchingItems
    const uniqueItems = matchingItems.filter(
      (item) =>
        !totalMarket.some(
          (marketItem) =>
            marketItem.dataKey === item.dataKey &&
            marketItem.segment === item.segment &&
            marketItem.type === item.type
        )
    );
    const sortedItems = sortByBrandCategory(uniqueItems);
    const keyIndex = totalMarket.findIndex(
      (category) =>
        category.dataKey === masterKey &&
        category.segment === key &&
        category.type === "Default"
    );

    if (keyIndex !== -1) {
      // Insert unique items at the correct position
      totalMarket.splice(keyIndex + 1, 0, ...sortedItems);
    } else {
      // Add unique items to the end
      totalMarket.push(...sortedItems);
    }

    updatedData["total market"] = totalMarket;
    return updatedData;
  };

  const updateFilteredDataWithoutSubCategory = (
    key: string,
    matchingItems: CountryData["total market"],
    prevData: CountryData
  ) => {
    const updatedData = { ...prevData };
    const totalMarket = updatedData["total market"] || [];

    // Check if matching items already exist to prevent duplicates
    const existingItems = totalMarket.some(
      (cat) => cat.segment === key && cat.type === "Default"
    );

    if (!existingItems) {
      const keyIndex = totalMarket.findIndex(
        (category) => category.type === key
      );
      const updatedMatchingItems = moveAbiToLastPosition(matchingItems);

      if (keyIndex !== -1) {
        // Insert matching items just after the key
        totalMarket.splice(keyIndex + 1, 0, ...updatedMatchingItems);
      } else {
        // If key is not found, append matching items at the end
        totalMarket.push(...updatedMatchingItems);
      }

      updatedData["total market"] = totalMarket;
    }

    return updatedData;
  };

  const updateFilteredData = (
    key: string,
    masterKey: string,
    isSubCategory: boolean
  ) => {
    const doesKeyExist =
      filteredData?.["total market"]?.some(
        (cat) =>
          cat.segment === key &&
          (isSubCategory
            ? cat.type !== "Default" && cat.dataKey === masterKey
            : true)
      ) ?? false;

    if (doesKeyExist) {
      // Remove entries with the same segment
      setFilteredData((prevData) => {
        if (!prevData) return prevData;
        return removeEntriesWithSameSegment(
          key,
          masterKey,
          isSubCategory,
          prevData
        );
      });
      return;
    }

    const matchingItems = findMatchingItems(
      key,
      masterKey,
      isSubCategory,
      data
    );

    // Update filteredData with matching items
    setFilteredData((prevData) => {
      if (!prevData) {
        return prevData; // Return early if prevData is null
      }

      if (isSubCategory) {
        return updateFilteredDataWithSubCategory(
          key,
          masterKey,
          matchingItems,
          prevData
        );
      } else {
        return updateFilteredDataWithoutSubCategory(
          key,
          matchingItems,
          prevData
        );
      }
    });
  };

  const handleSubCategoryClick = (key: string, masterKey: string) => {
    updateFilteredData(key, masterKey, true);
  };

  const handlesegmentClick = (key: string) => {
    updateFilteredData(key, "", false);
  };

  const handleOpenCat = (category: Category, id: string) => {
    setExpandedKeys((prevKeys) => {
      const newKeys = new Set(prevKeys);
      if (newKeys.has(id)) {
        newKeys.delete(id);
      } else {
        newKeys.add(id);
      }
      return newKeys;
    });
    const { type } = category;
    const { segment } = category;

    if (type && type !== "Default" && !segment) {
      handlesegmentClick(type);
    }
    if (segment && segment !== "") {
      const masterKey = category?.dataKey ?? "";
      if (masterKey && masterKey !== "") {
        if (type !== "Default") return;
        handleSubCategoryClick(segment, masterKey);
      }
    }
  };

  if (!filteredData) {
    return <Loader dH="40vh" dW="16vw" />;
  }

  // Helper function to filter items based on type
  const filterByType = (item: { brand: string }, type: string) =>
    item.brand === type;

  // Helper function to filter values based on packType
  const filterValuesByPackType = (values: PackSize[], packType: string) =>
    values.filter((value: PackSize) => value.type === packType);

  // Main function
  const filterItems = (
    country: string,
    type: string,
    dynamicKey: string,
    entry: Entry
  ) => {
    const popUpData: PopupData = isSwitchOn ? absPopup : perPopup;
    const countryData = popUpData[country];

    if (!countryData) return []; // Return an empty array if no data exists

    // Get the packType based on entry type
    const packType = entry.type === "RB" ? "RGB" : entry.type;
    // Filter the country data based on the brand type and dynamic key
    return countryData
      .filter((item) => filterByType(item, type))
      .flatMap((item) =>
        item.items.flatMap((subItem) => {
          const key = Object.keys(subItem)[0];
          if (key === dynamicKey) {
            const values = Object.values(subItem)[0];
            return filterValuesByPackType(values, packType); // Use the helper function for filtering
          }
          return [];
        })
      );
  };

  const mapToModalData = (filteredItems: EntryItem[], type: string) =>
    filteredItems[0]?.pack_sizes.map((pack) => ({
      brand: type ?? "",
      packType: filteredItems[0]?.type ?? "",
      packSize: String(pack.type),
      volume: pack.value ?? "",
    }));

  const handleClick = (
    category: Category,
    entry: Entry,
    dynamicKey: string,
    country: string
  ) => {
    const { type, segment } = category;

    if (type === "Default" || segment === "") {
      return;
    }

    const filteredItems = filterItems(country, type ?? "", dynamicKey, entry);

    if (filteredItems.length > 0 && type && segment) {
      const updatePopupData = {
        brand: type,
        segment,
        category: dynamicKey,
      };
      const newModalData = mapToModalData(filteredItems, type) || [];

      setModalData(newModalData);

      setSelectedCategory(updatePopupData);
    }

    onOpen();
  };

  const renderTotalMarket = () =>
    filteredData["total market"]
      .filter((category) => category.dataKey || category.type !== "Default")
      .map((category, index) =>
        renderCategory(category, index, handleOpenCat, expandedKeys)
      );

  const renderFilteredData = () =>
    Object.entries(filteredData).map(([key, value]) => {
      if (!Array.isArray(value)) return null;
      return (
        <Box key={`${key}--${uuidv4()}`}>
          {key === "total market" && (
            <Flex gap={6} justifyContent="space-between" flexWrap="wrap">
              {["Entry", "Frequency", "Intensity"].map((label) =>
                renderLabelsGrid(label)
              )}
            </Flex>
          )}
          {value.map((category) => (
            <Box key={`${category.type}-main-data-${uuidv4()}`}>
              {renderCategoryGrid(category, filteredData, handleClick)}
            </Box>
          ))}
        </Box>
      );
    });

  return (
    <Box p={4} bg="white" boxShadow="md" borderRadius="md" mb={10}>
      <Flex alignItems="flex-start" mt={2} gap={4} mb={2}>
        <Flex alignItems="flex-start" direction="column" width="15%">
          <Flex className="globalTableFilter" gap="2%">
            <Image
              w="60px"
              src={countryFlags[filteredData.country] ?? ""}
              alt={filteredData.country}
            />
            <Text variant="custom" ml={1}>
              {filteredData.country}
            </Text>
          </Flex>
          <Flex
            mb={2}
            h="34px"
            padding="8px"
            width="100%"
            borderRadius="md"
            fontWeight="600"
            justifyContent="space-between"
            alignItems="center"
            cursor="pointer"
            height="35px"
            bg="#DCDCDC"
          >
            <Text variant="custom" ml={1} isTruncated>
              Total Market
            </Text>
            <FiChevronUp />
          </Flex>
          {renderTotalMarket()}
        </Flex>
        <Flex direction="column" width="85%">
          {renderFilteredData()}
        </Flex>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent width="70%" maxWidth="70%" py={2}>
          <ModalHeader py={0} w="96%">
            <Flex justifyContent="space-between">
              <Flex gap={2}>
                <Text variant="custom" fontSize="15px">
                  {selectedCategory?.segment}{" "}
                </Text>
                <Text variant="custom" fontSize="15px">
                  {" "}
                  &gt;{" "}
                </Text>
                <Text variant="custom" fontSize="15px">
                  {" "}
                  {selectedCategory?.brand}{" "}
                </Text>
                <Text variant="custom" fontSize="15px">
                  {" "}
                  &gt;{" "}
                </Text>
                <Text variant="custom" fontSize="15px">
                  {selectedCategory?.category.toUpperCase()}
                </Text>
              </Flex>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <DynamicTable
              data={modalData}
              columns={columns}
              name="exploreTable"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ExploreCountry;
