import "./App.css";
import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import {
  type IMsalContext,
  MsalAuthenticationResult,
  MsalAuthenticationTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { ChakraProvider, Image } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import footer from "innovate/assets/images/footer.png";
import Loader from "innovate/components/common/Loader";
import Layout from "innovate/layout/Layout";
import Homepage from "innovate/pages/Homepage/Homepage";
import Unauthorized from "innovate/pages/Unauthorized/Unauthorized";
import router from "innovate/routes";
import theme from "innovate/theme/index";
import { FunctionComponent, Suspense } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  RouterProvider,
  Routes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const queryClient = new QueryClient();

const App = () => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <ChakraProvider theme={theme}>
      {isAuthenticated ? (
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={{}}
          loadingComponent={
            Loader as unknown as FunctionComponent<IMsalContext>
          }
          errorComponent={
            Unauthorized as unknown as FunctionComponent<MsalAuthenticationResult>
          }
        >
          <QueryClientProvider client={queryClient}>
            <Suspense fallback={<Loader dH="90vh" dW="20vw" />}>
              <Layout>
                <RouterProvider router={router} />
              </Layout>
            </Suspense>
          </QueryClientProvider>
        </MsalAuthenticationTemplate>
      ) : (
        <UnauthenticatedTemplate>
          <Layout>
            <Router>
              <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </Router>
          </Layout>
        </UnauthenticatedTemplate>
      )}

      <ToastContainer />
    </ChakraProvider>
  );
};

export default App;
