import { Text, Tooltip } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { FC, useCallback, useMemo } from "react";
import { ActionMeta, GroupBase, MultiValue } from "react-select";
import { SelectComponents } from "react-select/dist/declarations/src/components";

export type Option = {
  value: string;
  label: string;
};

export type MultiSelectInputProps = {
  value: Option[];
  options: Option[];
  onChange: (value: Option[], actionMeta: ActionMeta<Option>) => void;
  disabled?: boolean;
  name: string;
  id?: string;
  placeholder: string;
};

const SELECT_ALL = "<SELECT_ALL>";
const MAX_TO_SHOW = 1;
const getLabel = (inputType: string) => {
  if (inputType === "Districts") return "National";
  if (inputType === "AccessSelect") return "Global";
  return "All";
};

const TooltipRenderer: SelectComponents<
  Option,
  true,
  GroupBase<Option>
>["MultiValueContainer"] = ({
  data,
  sx,
  selectProps: { value, options, name },
}) => {
  if (!value || !("length" in value)) return null;
  if (value.length >= options.length - 1) {
    if (data.value === value[0]?.value) {
      return (
        <Tooltip label={value.map((option) => option.label).join(", ")}>
          <Text sx={sx}>{getLabel(name)}</Text>
        </Tooltip>
      );
    } else {
      return null;
    }
  } else if (value.slice(0, MAX_TO_SHOW).some((v) => v.value === data.value)) {
    return <Text sx={sx}>{data.label}</Text>;
  } else if (data.value === value[MAX_TO_SHOW]?.value) {
    return (
      <Tooltip
        label={value
          .slice(MAX_TO_SHOW)
          .map((option) => option.label)
          .join(", ")}
      >
        <Text sx={sx}>{`+${value.length - MAX_TO_SHOW}`}</Text>
      </Tooltip>
    );
  } else {
    return null;
  }
};

const MultiSelectInput: FC<MultiSelectInputProps> = ({
  name,
  id,
  value,
  options,
  onChange,
  disabled,
  placeholder,
}) => {
  const isSelectAllSelected = value.length >= options.length;
  const isOptionSelected = useCallback(
    (option: Option): boolean =>
      isSelectAllSelected || value.some(({ value }) => value === option.value),
    [value, isSelectAllSelected]
  );

  const fullOptions = useMemo(
    () => [
      {
        value: SELECT_ALL,
        label: getLabel(name),
      },
      ...options,
    ],
    [options, name]
  );

  const handleOnChange = useCallback(
    (newValue: MultiValue<Option>, actionMeta: ActionMeta<Option>): void => {
      const { action, option, removedValue } = actionMeta;
      if (action === "select-option" && option?.value === SELECT_ALL) {
        onChange(options, actionMeta);
      } else if (
        (action === "deselect-option" && option?.value === SELECT_ALL) ||
        (action === "remove-value" && removedValue?.value === SELECT_ALL)
      ) {
        onChange([], actionMeta);
      } else if (
        actionMeta.action === "deselect-option" &&
        isSelectAllSelected
      ) {
        const updatedOptions = options.filter(
          ({ value }) => value !== option?.value
        );
        onChange(updatedOptions, actionMeta);
      } else {
        onChange(newValue, actionMeta);
      }
    },
    [isSelectAllSelected, onChange, options]
  );

  return (
    <Select
      name={name}
      id={id}
      // menuIsOpen={true}
      required
      chakraStyles={{
        menuList: (baseStyles) => ({
          ...baseStyles,
          maxHeight: "168px",
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          fontWeight: "600",
          fontSize: "10px",
          textAlign: name === "AccessSelect" ? "left" : "center",
          color: "#1E1E1ECC",
          bg: "white",
          minWidth: "150px",
          // width: name === "ResultPieChart" ? (state.hasValue ? "auto" : "180px") : state.hasValue ? "auto" : "auto",
          minHeight: name === "ResultPieChart" ? "30px" : undefined,

          padding: "0 16px 0",
          backgroundColor: "white !important",
          borderColor: state.isFocused
            ? "primaryBlack !important"
            : "border !important",
          borderRadius: "8px",
          boxShadow: "none !important",
        }),
        placeholder: (baseStyles) => ({
          ...baseStyles,
          color: "#1E1E1ECC",
        }),
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          px: "0",
          fontSize: "10px",
          fontWeight: "600",
        }),
        multiValue: (baseStyles) => ({
          ...baseStyles,
          fontWeight: "700",
          color: "#1E1E1ECC",
          bg: "linear-gradient(rgba(245, 224, 3, 0.5), rgba(229, 182, 17, 0.5))",
          fontSize: name === "shareUser" ? "8px !important" : "10px !important",
        }),
        dropdownIndicator: (baseStyles) => ({
          ...baseStyles,
          px: "2px",
          bg: "none",
          cursor: "pointer",
        }),
        clearIndicator: (baseStyles) => ({
          ...baseStyles,
          bg: "none",
          fontSize: "10px",
          w: "auto",
          px: "2px",
          mx: "0",
        }),
        input: (baseStyles) => ({
          ...baseStyles,
          fontSize: "10px", // Increase font size
          fontWeight: "600", // Increase font weight
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          color: "#1E1E1ECC !important",
          textTransform: "uppercase",
          fontSize: name === "ResultPieChart" ? "9px" : "10px",
          fontWeight: state.isSelected ? "700" : "600",
          // background: state.isSelected ? "border !important" : "none",
          background: state.isSelected
            ? "linear-gradient(rgba(245, 224, 3, 0.5), rgba(229, 182, 17, 0.5)) !important"
            : "none",
          // background: state.isSelected ? "#e6c200 !important" : "none",

          _hover: {
            color: "#1E1E1ECC",

            bg: "linear-gradient(rgba(245, 224, 3, 0.5), rgba(229, 182, 17, 0.5))",
          },
        }),
      }}
      selectedOptionStyle="check"
      isOptionSelected={isOptionSelected}
      options={fullOptions}
      value={value}
      onChange={handleOnChange}
      classNamePrefix="topSelectFilter amt"
      className="topSelectFilter basic-multi-select"
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      isMulti
      placeholder={placeholder}
      isDisabled={disabled ?? false}
      components={{
        IndicatorSeparator: null,
        MultiValueContainer: TooltipRenderer,
      }}
    />
  );
};

MultiSelectInput.defaultProps = {
  disabled: false,
  id: undefined,
};

export default MultiSelectInput;
