import { Center, Grid, GridItem, Text } from "@chakra-ui/react";
import { Category } from "innovate/utils/types";
import React from "react";

export interface Entry {
  type: string;
  value: string;
}

interface DataObject {
  [key: string]: Entry[];
}

interface ExploreCountryProps {
  data: DataObject;
  category: Category;
  color: string;
  country: string;
  handleClick: (
    category: Category,
    entry: Entry,
    dynamicKey: string,
    country: string
  ) => void; // Update to accept an argument
}

const InnoExploreGrid: React.FC<ExploreCountryProps> = ({
  data,
  category,
  color,
  country,
  handleClick,
}) => {
  const dynamicKey = Object.keys(data)[0] as keyof DataObject;
  const entries = data[dynamicKey];
  const { type } = category;
  const { segment } = category;
  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={4}>
      {entries?.map((entry: Entry) => (
        <GridItem
          onClick={
            type === "Default" ||
            segment === "" ||
            entry.value === "0" ||
            entry.value === "0%"
              ? undefined
              : () => handleClick(category, entry, String(dynamicKey), country)
          } // Correct spelling of onClick
          cursor={
            type === "Default" ||
            segment === "" ||
            entry.value === "0" ||
            entry.value === "0%"
              ? "default"
              : "pointer"
          }
          key={entry.type}
          h="35px"
          bg={color}
          p="8px"
          borderRadius="md"
        >
          <Center>
            <Text fontWeight={600} fontSize="11px">
              {entry.value === "0" || entry.value === "0%" ? "-" : entry.value}{" "}
            </Text>
          </Center>
        </GridItem>
      ))}
    </Grid>
  );
};

export default InnoExploreGrid;
