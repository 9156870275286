/* eslint-disable */
// @ts-nocheck
import { IMsalContext, useMsal } from "@azure/msal-react";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios, { type AxiosRequestConfig, AxiosResponse } from "axios";
import { fixed } from "innovate/utils/Constants";
import {
  ApprovedUser,
  Flavour,
  ScenarioType,
  UserData,
} from "innovate/utils/types";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AUTH_TOKEN_KEY = "Auth_token";
export type Response<T> =
  | {
      simulation: boolean;
      success: true;
      data: T;
    }
  | { success: false; err?: AxiosResponse };

type AuthResponse = { token_type: string; access_token: string };
type NavigateFunction = ReturnType<typeof useNavigate>;

const getAuthToken = async (
  msalDetails: IMsalContext,
  navigate: NavigateFunction
) => {
  if (!sessionStorage.getItem(AUTH_TOKEN_KEY)) {
    const account = msalDetails.accounts[0];
    const postData = {
      email: account?.username,
      username: account?.name,
    };
    let authResponse;
    try {
      authResponse = await axios<AuthResponse>({
        url: `${fixed}users/sso-login`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: postData,
      });
    } catch (err) {
      navigate("/404", {
        state: {
          apiEndpoint: `${fixed}users/sso-login`,
          status: authResponse?.status,
        },
      });
      console.debug("error in getting token");
      return null;
    }

    if (authResponse.status === 200) {
      sessionStorage.setItem(AUTH_TOKEN_KEY, authResponse.data.access_token);
    } else if (authResponse.status === 404) {
      navigate("/404", {
        state: { apiEndpoint: `${fixed}users/sso-login`, status: "404" },
      });
      return null;
    } else {
      navigate("/404", {
        state: {
          apiEndpoint: `${fixed}users/sso-login`,
          status: authResponse.status,
        },
      });
      console.error(authResponse);
      return null;
    }
  }
  const token = sessionStorage.getItem(AUTH_TOKEN_KEY);
  if (token) {
    return `Bearer ${token}`;
  } else {
    return null;
  }
};

export const useIsAuthorized = () => {
  const msalDetails = useMsal();
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      try {
        await getAuthToken(msalDetails, navigate);
      } catch (err) {
        console.debug(err);
      }
    })();
  }, [msalDetails, navigate]);
};

const setHeaders = (
  config: AxiosRequestConfig,
  token: string
): AxiosRequestConfig => {
  return {
    ...config,
    headers: {
      ...(config.headers ?? {}),
      Authorization: token,
      "Cache-Control": "no-store,no-cache,",
      Pragma: "no-cache",
    },
  };
};

const handleAuthError = async <T>(
  err: any,
  msalDetails: IMsalContext,
  navigate: NavigateFunction,
  config: AxiosRequestConfig
): Promise<Response<T>> => {
  if (axios.isAxiosError(err) && err.response?.status === 401) {
    console.log("Token expired 401");
    sessionStorage.removeItem(AUTH_TOKEN_KEY);
    const newToken = await getAuthToken(msalDetails, navigate);

    if (!newToken) return { success: false };
    console.log("newToken", newToken);

    // Retry the original request with the new token
    const retryConfig = setHeaders(config, newToken);
    return await retryRequest<T>(retryConfig, msalDetails, navigate);
  }

  const statusCode = err.response?.status;
  console.log("Token expired unknown", statusCode);
  return { success: false, err: err.response };
};

const retryRequest = async <T>(
  retryConfig: AxiosRequestConfig,
): Promise<Response<T>> => {
  try {
    const retryResponse = await axios<T>(retryConfig);
    return {
      success: true,
      data: retryResponse.data,
    };
  } catch (retryErr) {
    console.error("retryErr", retryErr);
    return { success: false, err: retryErr.response };
  }
};

const httpCall = async <T>(
  config: AxiosRequestConfig,
  msalDetails: IMsalContext,
  navigate: NavigateFunction
): Promise<Response<T>> => {
  const token = await getAuthToken(msalDetails, navigate);
  if (!token) return { success: false };

  const headerConfig = setHeaders(config, token);

  try {
    const response = await axios<T>(headerConfig);
    return {
      success: true,
      data: response.data,
    };
  } catch (err) {
    return await handleAuthError<T>(err, msalDetails, navigate, config);
  }
};

export const useAuthenticatedQuery = <T>(
  queryKey: unknown[],
  queryConfig: AxiosRequestConfig,
  options?: {
    refetchOnWindowFocus?: boolean;
    enabled?: boolean;
  }
): UseQueryResult<Response<T>> => {
  const msalDetails = useMsal();
  const navigate = useNavigate();
  const result = useQuery({
    queryKey,
    queryFn: () => httpCall<T>(queryConfig, msalDetails, navigate),
    refetchOnWindowFocus: options?.refetchOnWindowFocus ?? false,
    retry: false,
    enabled: options?.enabled ?? true, // Only run the query if enabled is true
  });
  const { isPending, error, data } = result;
  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error, isPending, data, queryKey]);
  return result;
};

export const useAuthenticatedMutation = <T, MutationData>(
  mutationKey: unknown[],
  getMutationConfig: (data: MutationData) => AxiosRequestConfig
): UseMutationResult<Response<T>, unknown, MutationData, unknown> => {
  const msalDetails = useMsal();
  const navigate = useNavigate();
  const result = useMutation<Response<T>, unknown, MutationData, unknown>({
    mutationKey,
    mutationFn: (data: MutationData) =>
      httpCall<T>(getMutationConfig(data), msalDetails, navigate),
    retry: false,
  });
  const { isIdle, error, data } = result;
  useEffect(() => {
    if (error) {
      console.error("error");
    }
  }, [error, isIdle, data, mutationKey]);
  return result;
};
type ResponseData = {
  is_admin: boolean;
  success: boolean;
  data?: {
    is_admin?: boolean;
  };
};
type scenarioData = {
  simulations: ScenarioType[];
  success: boolean;
};
type ApprovedCountriesResponse = {
  markets: string[];
  success: boolean;
  data: {
    markets: string[];
  };
};

export interface SimulationNamesResponse {
  simulations: Flavour[];
  success: boolean;
  data: {
    simulations: Flavour[];
  };
}

export interface UserResponse {
  success: boolean;
  data: UserData[];
}
export interface User2Response {
  market: never[];
  role_id: number;
  email: string;
  name: string;
  success: boolean;
  data: UserData[];
}
export const useAdminAuthorized = () => {
  const { data, isLoading } = useAuthenticatedQuery<ResponseData>(["getUser"], {
    url: `${fixed}users/is_admin`,
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });

  const isAdmin = data?.success ? data.data?.is_admin : undefined;
  return { isAdmin, isLoading };
};

export const useScenarios = (refetch: boolean) => {
  const { data, isLoading, isError } = useAuthenticatedQuery<scenarioData>(
    ["getScenarios", refetch],
    {
      url: `${fixed}simulations`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );

  const fetchedScenarios = data?.success ? data.data.simulations : [];

  return {
    fetchedScenarios,
    isLoading,
    isError,
  };
};
export const useSharedScenarios = () => {
  const { data, isLoading } = useAuthenticatedQuery<scenarioData>(
    ["getSharedScenarios"],
    {
      url: `${fixed}simulations/sharedsimulations`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );

  const isSharedLoading = isLoading;

  const fetchedSharedScenarios = data?.success ? data.data.simulations : [];
  return {
    fetchedSharedScenarios,
    isSharedLoading,
  };
};

export const GetArpprovedCountries = () => {
  const { data, isLoading } = useAuthenticatedQuery<ApprovedCountriesResponse>(
    ["approvedCountries"],
    {
      url: `${fixed}users/get_markets`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );

  const isCountryLoading = isLoading;
  const approvedCountries = data?.success ? data.data.markets : [];
  return { approvedCountries, isCountryLoading };
};

export const GetSimulationNames = () => {
  const { data, isLoading } = useAuthenticatedQuery<SimulationNamesResponse>(
    ["currentScenario"],
    {
      url: `${fixed}get_simulation_names`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );
  console.log("data", data);
  const SimulationName = data?.success ? data.data.simulations : [];
  return { SimulationName, isLoading };
};

export const GetApprovedUsers = () => {
  const { data, isLoading } = useAuthenticatedQuery<ApprovedUser>(
    ["getApprovedUser"],
    {
      url: `${fixed}users/approved`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );

  const isUserLoading = isLoading;
  const approvedUSers = data?.success
    ? data.data
    : {
        SA: [],
        BR: [],
      };
  return { approvedUSers, isUserLoading };
};

export const GetApprovalList = () => {
  const { data, isLoading } = useAuthenticatedQuery<UserResponse>(
    ["userList"],
    {
      url: `${fixed}users`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );
  const approved = data?.success ? data.data : {};
  return {
    approved,
    isLoading,
  };
};

export const GetPendingApproval = () => {
  const { data, isLoading } = useAuthenticatedQuery<UserResponse>(
    ["pendingUsers"],
    {
      url: `${fixed}users/pending`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );

  const pending = data?.success ? data.data : [];
  return {
    pending,
    isLoading,
  };
};

export const GetUser = (id: string) => {
  const { data, isLoading } = useAuthenticatedQuery<User2Response>(
    ["EditUser"],
    {
      url: `${fixed}user/${id}`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );
  const user = data?.success && data.data ? data.data : null;

  return {
    user,
    isLoading,
  };
};

export const logout = (msalDetails: IMsalContext) => {
  sessionStorage.removeItem(AUTH_TOKEN_KEY);
  msalDetails.instance.logout();
};

const fetchQuery = async (
  endpoint: string,
  data?: unknown,
  method: string = "POST"
) => {
  const options: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (method !== "GET" && method !== "HEAD") {
    options.body = JSON.stringify(data);
  }

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}${endpoint}`,
      options
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();

    toast.success("Request successful");
    return responseData;
  } catch (error) {
    const errorMessage = (error as Error).message;
    toast.error(`Request failed: ${errorMessage}`);
    throw error;
  }
};

export default fetchQuery;
